import React, { useState, useEffect, useContext } from 'react';
import Checkbox                       from '@mui/material/Checkbox';
import InputLabel                     from '@mui/material/InputLabel';
import Input                          from '@mui/material/Input';
import InputAdornment                 from '@mui/material/InputAdornment';
import FormControl                    from '@mui/material/FormControl';
import IconButton                     from '@mui/material/IconButton';
import Button                         from '@mui/material/Button';
import Dialog                         from '@mui/material/Dialog';
import DialogActions                  from '@mui/material/DialogActions';
import DialogContent                  from '@mui/material/DialogContent';
import DialogTitle                    from '@mui/material/DialogTitle';

import axios                          from "axios";

import './UserEditing.css';
import utils                          from '../../util/CommonUtilities';
import { AuthContext }                from '../../contexts/authContext';
import { PopupConfirm }               from '../../components/PopupConfirm/PopupConfirm';
import { CustomCssTextField }         from "../../components/CustomCSS/CustomCSS.tsx";
import {  ActiveIcon
        , SupervisorAccountIcon
        , TraffickerIcon }          from '../../assets/icons/icons.js';
       /* , KeyIcon
          , VisibilityIcon
          , VisibilityOffIcon */  
import config from '../../config';

const bDebug = config.DEBUG && false;

function UserEditing( { options } ) {
    const auth = useContext(AuthContext);
    const 
         { bShowDialog, set_bShowDialog, oDialogOptions: oRow, loading, setLoading, sTitle, oMapLabelAndValues, reload, toggleNotifica, set_sIDRowOpened } = options
        // ,isInsNewUser  = !oDialogOptions || ( Object.keys(oDialogOptions).length === 0 )
        ,sOrigStatus   = oRow['FLAG_ATTIVO']
        ,[ sUserRole        ,set_sUserRole        ] = useState(oRow['FLAG_ADMIN'])
        ,[ sTrafficker      ,set_sTrafficker      ] = useState(oRow['FLAG_TRAFFICKER'])
        ,[ sUserStatus      ,set_sUserStatus      ] = useState(oRow['FLAG_ATTIVO'])
        ,[ sFullName        ,set_sFullName        ] = useState(oRow['FULL_NAME']) // Nome e Cognome
        ,[ sUserName        ,set_sUserName        ] = useState(oRow['USERNAME']) // Email
        ,[ sUserAgents      ,set_sUserAgents      ] = useState(oRow['AGENTE_COD'])
        ,[ oParamsModUser   ,set_oParamsModUser   ] = useState( {} )
        ,[ oParamsDelUser   ,set_oParamsDelUser   ] = useState( {} )
        ,[ oParamsResetPass ,set_oParamsResetPass ] = useState( {} )
        ,[ sPopupType       ,set_sPopupType       ] = useState('')
    ;
    const closePopup = () => set_sPopupType(''); 
    
    // !!! TODO  Fix FetchService
    const actionsOnUser = ({ params, actionType }) => {
        bDebug && console.log(params);
        if ( Object.keys( params || {} ).length ) {
            setLoading( true );
            ( async () => {
                try {
                    const response = await axios.post(
                        config.API_URL + '/manage-user'
                        , null
                        , {
                            headers: {
                                'Accept': 'application/json',
                                'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                            },
                            params : { pActionType: actionType
                                     , oParamsToDB: params
                                     ,...( sOrigStatus !== sUserStatus && { pToggleStatus: sUserStatus }) }
                        }
                    )
                    const { nRetVal, vErrorMessageDesc } = response.data;

                    if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                        //  Selezioniamo la chiave giusta da passare grazie ad actionType
                        // console.log(params);
                        toggleNotifica({  modifica      : `Utente  ${ params.pUserName } aggiornato`
                                        , cancellazione : `Utente  ${ params.pUserName } cancellato`
                                        , resetPassword : 'Password invalidata' }[actionType] + ' correttamente', 'success', 5 );
                        reload();
                    } else {
                        console.error(`Errore ${ vErrorMessageDesc || '' }`);
                        toggleNotifica( `Errore ${ vErrorMessageDesc || '' }`, 'error' );
                        setLoading( false );
                    }
                } catch ( err ) {
                    console.error('ERR 134: Failed', err);
                    toggleNotifica( `Interrogazione fallita`, 'error' );
                    setLoading( false );
                }
            })();
        }
    };

    useEffect(() => {
        if ( Object.keys(oParamsModUser).length ) {
            actionsOnUser({ params: oParamsModUser, actionType: 'modifica' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsModUser ]);

    useEffect(() => {
        if ( Object.keys(oParamsDelUser).length ) {
            actionsOnUser({ params: oParamsDelUser, actionType: 'cancellazione' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsDelUser ]);

    useEffect(() => {
        if ( Object.keys(oParamsResetPass).length ) {
            // actionsOnUser({ url: '/settings/del-user', params: oParamsDelUser, actionType: 'del' });
            actionsOnUser({ params: oParamsResetPass, actionType: 'resetPassword' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oParamsResetPass ]);

    const makeTextField = ( sKey, value, set_value ) => {
        const oMap = oMapLabelAndValues[sKey];

        if ( oMap ) {
            const format = oMap.format || (val => val);
            return <CustomCssTextField
                key      ={sKey}
                id       ={sKey}
                className={sKey}
                label    ={ oMap.label }
                value    ={ format( typeof value !== 'undefined' ? value : oRow[sKey] ) || '' }
                onChange ={ (event) => {
                    set_value( format( event.target.value ) );
                } }
                onClick  ={((event)=>{event.stopPropagation()})}
                disabled ={(sKey !== 'AGENTE_COD') ? !oMap.enabled : ( sUserRole !== 'Y' ? false : true ) }
                // In Tutti i casi controllo se il campo è enable, nel caso di agente_cod devo verificare se l'utente è admin
            />
        }
    };

    const makeCheckbox = ({ sFieldName, sState, setState }, cIcon ) => {
        const oMap = oMapLabelAndValues[sFieldName];
        // console.log(Icon)
        return <div className={sFieldName} >
            <div className="input-field flag-admin full-width">
                <div className='myFlexCreateUser'>
                    { cIcon }
                    <InputLabel className="inputLabel" id={ sFieldName + '-label' } disabled>&nbsp;&nbsp;{ oMap.label }</InputLabel>
                </div>
                <Checkbox
                    className="checkbox"
                    id       ={ sFieldName }
                    checked  ={ sState === 'Y' }
                    onChange ={ event => { setState(event.target.checked ? 'Y' : 'N' ); } }
                />
            </div>
        </div>
    }

    return <Dialog open={ bShowDialog } className="users editing-dialog" >

        <DialogTitle>{ sTitle }</DialogTitle>

        <DialogContent className='fixPad'>
            { loading ? <div>&nbsp;<br/>&nbsp;</div> : <div>{
                <form className="editFields" autoComplete="off">
                    { makeTextField( 'USERNAME'        ,sUserName   ,set_sUserName ) }
                    { makeTextField( 'FULL_NAME'       ,sFullName   ,set_sFullName   ) }
                    { makeTextField( 'AGENTE_COD'      ,sUserAgents ,set_sUserAgents ) }
                    { makeTextField( 'DATA_INSERIMENTO'     ) }
                    { makeTextField( 'DATA_ULTIMA_MODIFICA' ) }

                    {   ( sOrigStatus === 'N') 
                      ? makeTextField( 'DATA_CANCELLAZIONE' )
                      : null
                    }
                    {
                        makeCheckbox({
                             sFieldName:  'FLAG_ADMIN'
                            ,sState:      sUserRole
                            ,setState:    set_sUserRole
                            ,sFieldLabel: 'Administrator'
                        }, <SupervisorAccountIcon className={"noHover textDark"}/>)
                    }
                    {
                        makeCheckbox({
                             sFieldName:  'FLAG_TRAFFICKER'
                            ,sState:      sTrafficker
                            ,setState:    set_sTrafficker
                            ,sFieldLabel: 'Trafficker'
                                   
                        }, <TraffickerIcon className={"noHover textDark"}/>)
                    }
                    {
                        // MAURIZIO NON SI DEVE RESETTARE LA PASSWORD IN AUTOMATICO AD OGNI ATTIVAZIONE DELL'UTENZA, 
                        // DEVE ESSERE FATTO MANUALMENTE 08/10/2024
                        makeCheckbox({
                             sFieldName:  'FLAG_ATTIVO'
                            ,sState:      sUserStatus
                            ,setState:    set_sUserStatus
                            ,sFieldLabel: 'Attivo'
                        }, <ActiveIcon className={"noHover textDark"}/>)
                    }
                    {
                        ( sOrigStatus === 'N' || sUserStatus === 'N') && <div className='textRed'>
                                 Attenzione!
                            <br/>Un utente disattivo non potrà impostare
                            <br/>la propria password.
                        </div>
                    }
                    {
                        ( sOrigStatus === 'Y' ) && 
                        <Button
                            className="dianButtonLarge"
                            onClick={() => {
                                set_sPopupType('Reset Password');
                            }}
                        >Reset Password</Button>
                    }
                </form>
            }</div> }

            <PopupConfirm { ...{ 
                 oPopupOptions:  {
                     DialogTitle:       'Reset Password Forzato'
                    ,DialogContentText: <form className='centerText'>
                                            <span>Confermi di voler resettare la password di:</span><br/><br/>
                                            <span>{ sUserName }</span><br/><br/>
                                            <span>Sarà poi necessario procedere al recupero della password da parte dell'utente.</span>
                                        </form>
                        
                    ,onClick:           () => { set_oParamsResetPass({ pUserId: oRow.KUSER }); }
                    ,onCancel:          closePopup
                }
                ,sPopupType: (sPopupType === 'Reset Password')
                ,set_sPopupType 
            }} />

            <PopupConfirm { ...{ 
                 oPopupOptions:  {
                     DialogTitle:       'Elimina Utente'
                    ,DialogContentText: 'Sei sicuro di voler eliminare l\'utente "' + oRow.FULL_NAME + '" ?'
                    ,onClick:           () => { set_oParamsDelUser({ pUserId: oRow.KUSER ,pUserName       : sUserName }); }
                    ,onCancel:          closePopup
                    ,firstButtonLabel:  'elimina'
                    ,classFirstButton:  'popupRemove'
                }
                ,sPopupType: (sPopupType === 'delete')
                ,set_sPopupType 
            }} />
        </DialogContent>

        <DialogActions className='dialogButton'>
            { loading ? null :
                <>
                    <Button onClick={ ()=>{
                        set_oParamsModUser({
                             pUserId         : oRow.KUSER
                            ,pCognome        : sFullName
                            ,pUserName       : sUserName
                            ,pEMailAddress   : sUserName
                            ,pAgenteCod      : (sUserRole === 'Y') ? '' : sUserAgents
                            ,pFlagAdmin      : sUserRole
                            ,pFlagTrafficker : sTrafficker
                            ,pFlagEnabled    : sUserStatus
                            ,pFlagCognito    : oRow.FLAG_COGNITO
                            ,pParentUserId   : oRow.PARENT_KUSER
                            ,pAlerts         : oRow.ALERTS
                            ,pMenuItems      : oRow.MENU_ITEMS
                        });
                    } } className='popupConfirm'>salva</Button>
                    { ( sOrigStatus === 'N') && localStorage.getItem('userEmail').includes('@systrategy.it') && <>
                        <Button
                            className="popupRemove"
                            onClick={() => {
                                set_sPopupType('delete');
                                set_sIDRowOpened('');
                            }}
                        >elimina</Button>
                    </>}
                    <Button onClick={ ()=> {
                        set_bShowDialog(false);
                        set_sIDRowOpened('');
                        // reload();
                    } } className='popupAnnul'>annulla</Button>
                </>
            }
        </DialogActions>

    </Dialog>
}

export default UserEditing;