import './icons.css';
import React from 'react'
/*
    // Sintassi Riferimento CSS: i.icon::before

    // Dichiarazione per utilizzare props specifiche oltre alle classi CSS (Es ref per Tooltip)
    // export const AccountCircleIcon = (props) => <i {...props} className = { `icon fa-regular fa-circle-user ${ props.addClass || '' }` } />;
*/

const prepareIcon = ( iconaBaseCssClass ) => (
    React.forwardRef(
        (props, ref) => {
            const { className, ...restOfProps } = props;
            return <i
                {...restOfProps}
                { ...( ref && {ref} ) }
                className={ 'icon ' + iconaBaseCssClass + ' ' + ( className || '' ) }
            />
        }
    )
);

export const AccountCircleIcon          = prepareIcon('fa-regular fa-circle-user');
export const AccountCircleSolidIcon     = prepareIcon('fa-solid fa-circle-user');
export const ActiveIcon                 = prepareIcon('fa-regular fa-lightbulb-on');
export const AddCircleOutlineIcon       = prepareIcon('fa-sharp fa-regular fa-circle-plus');
export const AddIcon                    = prepareIcon('fa-solid fa-plus-large');
export const AdjustIcon                 = prepareIcon('fa-regular fa-sliders-up');
export const AlertIcon                  = prepareIcon('fa-regular fa-bell-exclamation');
export const AlertSlashIcon             = prepareIcon('fa-kit fa-regular-bell-exclamation-slash');
export const AlternateEmailIcon         = prepareIcon('fa-regular fa-at');
export const ArrowDownRightIcon         = prepareIcon('fa-solid fa-arrow-down-right');
export const ArrowUpRightIcon           = prepareIcon('fa-solid fa-arrow-up-right');
export const ArrowDown                  = prepareIcon('fa-sharp fa-solid fa-chevron-down');
export const ArrowLeft                  = prepareIcon('fa-sharp fa-solid fa-chevron-left');
export const ArrowRight                 = prepareIcon('fa-sharp fa-solid fa-chevron-right');
export const ArrowUp                    = prepareIcon('fa-sharp fa-solid fa-chevron-up');
export const AutoDistributionIcon       = prepareIcon('fa-regular fa-circle-nodes');
export const BellIcon                   = prepareIcon('fa-regular fa-bell');
export const CabinetIcon                = prepareIcon('fa-solid fa-cabinet-filing');
export const CalendarClockIcon          = prepareIcon('fa-regular fa-calendar-clock');
export const CachedIcon                 = prepareIcon('fa-regular fa-rotate');
export const CancelIcon                 = prepareIcon('fa-regular fa-xmark'); // X
export const CancelPresentationIcon     = prepareIcon('fa-regular fa-rectangle-xmark');
export const CheckIcon                  = prepareIcon('fa-regular fa-check');
export const CheckBoxFlagged            = prepareIcon('fa-regular fa-square-check');
export const CheckBoxEmpty              = prepareIcon('fa-regular fa-square'); // Square
export const CheckDoubleIcon            = prepareIcon('fa-sharp fa-solid fa-check-double');
export const CircleIcon                 = prepareIcon('fa-solid fa-circle-small');
export const CreditCardIcon             = prepareIcon('fa-regular fa-credit-card');
export const DashBoardIcon              = prepareIcon('fa-regular fa-solid fa-gauge');
export const DeleteIcon                 = prepareIcon('fa-solid fa-trash');
export const DistribuzioneCarichiIcon   = prepareIcon('fa-regular fa-scale-unbalanced-flip');
export const DoDisturbIcon              = prepareIcon('fa-regular fa-ban');
export const DoNotDisturbOnOutlinedIcon = prepareIcon('fa-sharp fa-regular fa-circle-minus');
export const DoneAllIcon                = prepareIcon('fa-sharp fa-solid fa-check-double');
export const DoubleArrowLeftIcon        = prepareIcon('fa-sharp fa-regular fa-angles-left');
export const DoubleArrowRightIcon       = prepareIcon('fa-sharp fa-regular fa-angles-right');
export const DownLoadIcon               = prepareIcon('fa-regular fa-file-arrow-down');
export const EditIcon                   = prepareIcon('fa-solid fa-pen');
export const EmailIcon                  = prepareIcon('fa-solid fa-envelope');
export const EqualIcon                  = prepareIcon('fa-solid fa-equals');
export const FilterIcon                 = prepareIcon('fa-regular fa-filter');
export const FolderOffIcon              = prepareIcon('fa-regular fa-folder-xmark');
export const FormatiIcon                = prepareIcon('fa-solid fa-shapes');
export const ForecastIcon               = prepareIcon('fa-solid fa-chart-mixed-up-circle-dollar');
export const FullScreenOnIcon           = prepareIcon('fa-regular fa-expand-wide');
export const FullScreenOffIcon          = prepareIcon('fa-regular fa-compress-wide');
export const GearIcon                   = prepareIcon('fa-sharp fa-solid fa-gear');
export const GroupIcon                  = prepareIcon('fa-solid fa-users');
export const InfoIcon                   = prepareIcon('fa-light fa-circle-info');
export const KeyIcon                    = prepareIcon('fa-regular fa-key-skeleton');
export const LeaderboardIcon            = prepareIcon('fa-sharp fa-solid fa-chart-simple');
export const LegendShowIcon             = prepareIcon('fa-light fa-list');
export const LegendHideIcon             = prepareIcon('fa-kit fa-light-list-slash');
export const LogoutIcon                 = prepareIcon('fa-solid fa-right-from-bracket');
export const MenuIcon                   = prepareIcon('fa-regular fa-bars');
export const MixBubbleChartIcon         = prepareIcon('fa-regular fa-chart-scatter-bubble');
export const MixLineChartIcon           = prepareIcon('fa-solid fa-chart-mixed');
export const MultipleIcon               = prepareIcon('fa-sharp fa-regular fa-rectangle-history');
export const NarvalIcon                 = prepareIcon('fa-regular fa-narwhal');
export const NotificationImportantIcon  = prepareIcon('fa-solid fa-bell-exclamation');
export const PieChartIcon               = prepareIcon('fa-regular fa-chart-pie');
export const PinIcon                    = prepareIcon('fa-sharp fa-regular fa-input-numeric');
export const SaveOutlinedIcon           = prepareIcon('fa-regular fa-floppy-disk');
export const SearchIcon                 = prepareIcon('fa-sharp fa-regular fa-magnifying-glass');
export const SellOrNotIcon              = prepareIcon('fa-regular fa-chart-pie-simple-circle-dollar');
export const SettingsIcon               = prepareIcon('fa-solid fa-screwdriver-wrench');
export const SsidChartIcon              = prepareIcon('fa-regular fa-chart-line-up');
export const SupervisorAccountIcon      = prepareIcon('fa-solid fa-crown'); // Richiesta da Maurizio Lanzi il 04/10/2024
export const NotSupervisorAccountIcon   = prepareIcon('fa-kit fa-regular-crown-slash');
export const TableChartIcon             = prepareIcon('fa-regular fa-table-columns');
export const TestIconIcon               = prepareIcon('fa-regular fa-viruses');
export const ThreeVerticalDot           = prepareIcon('fa-regular fa-ellipsis-vertical');
export const TraffickerIcon             = prepareIcon('fa-solid fa-user-pilot-tie');
export const TrendingDownIcon           = prepareIcon('fa-solid fa-arrow-trend-down');
export const TrendingUpIcon             = prepareIcon('fa-solid fa-arrow-trend-up');
export const UndoIcon                   = prepareIcon('fa-solid fa-rotate-left');
export const UploadIcon                 = prepareIcon('fa-sharp fa-solid fa-up-from-line');
export const UserIcon                   = prepareIcon('fa-solid fa-user');
export const VisibilityIcon             = prepareIcon('fa-regular fa-eye');
export const VisibilityOffIcon          = prepareIcon('fa-regular fa-eye-slash');