import React, { useState, useEffect, useContext } from 'react';
import {useNavigate}                              from 'react-router-dom';
import { Link, useParams }          from 'react-router-dom';
import { Button
       , ButtonGroup
       , Checkbox
       , FormControl
       , IconButton
       , InputAdornment
       , Tooltip }                  from '@mui/material';
import { LocalizationProvider }     from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment }            from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker }               from '@mui/x-date-pickers/DatePicker';
import axios                        from 'axios';
import moment                       from 'moment';
import 'moment/locale/it';

import './Allarmi.css';
import config                       from '../../config.js';
import { AuthContext }              from '../../contexts/authContext';
import { AppContext }               from '../../App.js';
import { SimpleTable }              from '../../components/SimpleTable/SimpleTable';
import { CustomCssTextField }       from '../../components/CustomCSS/CustomCSS.tsx';
// import AnagrafichePercentuale       from '../anagrafiche/Component/AnagrafichePercentuale';

import CircleProgress               from '../../components/CircleProgress/CircleProgress.tsx';
import Graph                        from '../../components/Graph/Graph';
import utils                        from '../../util/CommonUtilities';

import { ArrowLeft
       , ArrowRight
       , CachedIcon
       , CheckBoxFlagged
       , CheckBoxEmpty  
       , CheckDoubleIcon
       , DoubleArrowLeftIcon
       , DoubleArrowRightIcon
       , FolderOffIcon
       , LeaderboardIcon
       , MultipleIcon
       , SaveOutlinedIcon
       , SsidChartIcon
       , TableChartIcon
       , TrendingDownIcon
       , TrendingUpIcon  }           from '../../assets/icons/icons.js';

const bDebug = config.DEBUG && false;
// !!! ToDo Necessaria revisione tooltip allarme 1 => NewDescription: "Lineitem senza materiali associati che hanno la data di partenza o la data di fine all'interno del range temporale [ Data Inizio - OGGI, OGGI - Data Fine ] e aventi lo stato uguale a uno dei valori: DRAFT,INACTIVE,PENDING_APPROVAL,PAUSED"

const AllarmeDettaglionew = () => {
    
    const auth     = useContext(AuthContext);
    const navigate = useNavigate()
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);
    const { id, idDettaglio, dettaglio } = useParams();
    const kUser = localStorage.getItem('kUser');
    const isAdmin          = ( localStorage.getItem('flagAdmin') || '' ) === 'Y'
         ,aAlarmsToDisplay = (JSON.parse(localStorage.getItem('alarmsToDisplay') || "[{}]") ).map( o => Object.keys(o) ).flat(Infinity)
    const env = process.env.REACT_APP_ENV;
    const isDemo  = env.includes('demo');
    const headers = {
        'Accept'        : 'application/json',
        'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
    };

    if ( !isAdmin && !aAlarmsToDisplay.includes('A' + (id + '').padStart(2,'0'))) {
        navigate('/errorPage404');
    }
    
    const
         [ loading                   ,setLoading                    ] = useState(true)
        ,[ oInfoPage                 ,set_oInfoPage                 ] = useState({ sNewPageName: `` ,sNewPageSubName: '' }) // Variabile per contenere le informazioni necessarie per la pagina in dettaglio una volta entrati nel secondo livello, come titolo, id dell'oggetto in questione, sottotitolo
        ,[ sAllarmeName              ,set_sAllarmeName              ] = useState('') // State per contenere il Nome dell'Allarme in Dettaglio
        ,[ sAllarmeType              ,set_sAllarmeType              ] = useState('') // State per contenere il Tipo dell'Allarme in Dettaglio
        ,[ sAllarmeTooltip           ,set_sAllarmeTooltip           ] = useState('') // State per descrizione Allarme da visualizzare in Tooltip
        ,[ sFieldList                ,set_sFieldList                ] = useState('') // Variabile per contenere l'elenco dei nomi delle colonne in unica stinga da splittare
        ,[ aoAllarmeDettaglioHeaders ,set_aoAllarmeDettaglioHeaders ] = useState([]) // State di Object per contenere i nomi delle colonne e le relative proprietà di colonna
        ,[ aoAllarmeDettaglio        ,set_AllarmeDettaglio          ] = useState([]) // State dei valori da inserire nella tabella
        ,[ aoRecordsFromDB           ,set_aoRecordsFromDB           ] = useState([]) // State per contenere i record della tabella dettaglio ottenuti direttamente dal DB
        ,[ oGetParams                ,set_oGetParams                ] = useState({ getAlertDetailsParams: true, nAlertId: id }) // State per contenere i parametri per la chiamata alla Lambda get, Dichiararla in questo modo mi permette di averla pronta per la prima chiamata(sempre uguale) e di non entrare nella useEffect associata a oGetParams
        ,[ oValues                   ,set_oValues                   ] = useState(null) // State per contenere il range di valori per la chiamata alla Lambda get
        ,[ sModifyTarget             ,set_sModifyTarget             ] = useState('')   // State per indicare quale riga (corrispondente all'id) si vuole modificare
        ,[ vModifyContent            ,set_vModifyContent            ] = useState(null) // State che indica il valore modificato
        ,[ onReload                  ,set_onReload                  ] = useState(null) // State per triggerare il lancio della chiamata
        ,[ sGraphEnabled             ,set_sGraphEnabled             ] = useState('')   // State per Identificare se si vuole visualizzare il Grafico a Linee ('line'), a Barre ('bar') oppure la tabella ('')
        ,[ dataPrima                 ,set_dataPrima                 ] = useState({ data: moment(), diffDays : 0})
        ,[ dataDopo                  ,set_dataDopo                  ] = useState({ data: moment(), diffDays : 0})
        ,[ boValueOnReload           ,set_boValueOnReload           ] = useState(null)
        ,[ focusDatePicker           ,set_focusDatePicker           ] = useState('')
        ,[ isFilterDateTable         ,set_isFilterDateTable         ] = useState(false) // State per indicare se si tratta di un allarme riguardante i lineItem così da mostrare il datepiker per filtrare i record (no allarm 1 & 10)
        ,[ isAlreadyFilter           ,set_isAlreadyFilter           ] = useState(false)
        ,[ odFilterTableDate         ,set_odFilterTableDate         ] = useState({ 
            // Oggetto composto da una coppia di triplette di date,
            // utilizzate per filtrare dinamicamente la tabella senza dover rilanciare la chiamata al db.
            // Ogni tripletta si riferisce o alla data inizio o a quella di fine:
            // il primo elemento della tripletta indica la data selezionata,
            // mentre la coppia min e max indica il range di estremi necessario per popolare il DatePicker
                dDataInizio: moment(), minDateInizio: moment(), maxDateInizio: moment(),
                dDataFine:   moment(), minDateFine:   moment(), maxDateFine:   moment() })
        ,[ bCaricaDettaglio          ,set_bCaricaDettaglio          ] = useState(null)
        ,[ sVerifiedTarget           ,set_sVerifiedTarget           ] = useState('')
        ,formatDateAll = (v) => ( v === moment().format('YYYYMMDD') ) 
                            ? 'OGGI' 
                            : `${utils.formatDateTime(v, {
                                     input       : 'YYYYMMDD'
                                    ,output      : 'ddd DD MMM YY'
                                })}`
    ;


    /* #region Mapping Obj */
    const asTypeInPerc = [ 'HOUSE', 'SPONSORSHIP', 'NETWORK' ];
    const asNoFilterDateTable = [ '1', '10' ];

    const oMappingInputs = {
         pDaysBefore    :{ label:'Data inizio'          , type: 'date'   , text: '' }
        ,pDaysAfter     :{ label:'Data fine'            , type: 'date'   , text: '' }
        ,pImpDeliv      :{ label:'Imps Erogate'         , type: 'text'   , text: 'Numero minimo di Impressions Delivered per campagne con Scheduled espresso in percentuale' }
        ,pImpDelivPerc  :{ label:'Imps Erogate %'       , type: 'text'   , text: 'Percentuale minima di Impressions Delivered per campagne con Scheduled espresso in numero assoluto' }
    //  ,pPlacementId   :{ label:'Placement Id'         , type: 'text'   , text: '' }
    //  ,pAdUnitId      :{ label:'AdUnit Id'            , type: 'text'   , text: '' }
    //  ,pDaysGraph     :{ label:'Days Graph'           , type: 'text'   , text: '' }
    //  ,pMMFastDays    :{ label:'MM Fast Days'         , type: 'text'   , text: '' }
    //  ,pMMSlowDays    :{ label:'MM Slow Days'         , type: 'text'   , text: '' }
    //  ,pMMFastPendDays:{ label:'MM Fast Pending Days' , type: 'text'   , text: '' }
        ,pDays          :{ label:'Dal giorno'           , type: 'date'   , text: `Ad Unit che dal ${ dataPrima?.data?.format('D MMM YYYY') || '' } ad oggi hanno dato di storico a zero` }
        ,pViewability        :{ label:'Viewability'         , type: 'textNum' }
        ,pViewabilityDefault :{ label:'Default Viewability' , type: 'textNum' }
    };

    const oMappingField = {
         'BACINO'        : { 'description': 'Bacino'              }
        ,'DATA_RIF'      : { 'description': 'Data Rif'            }
        ,'BACINO_MM_FAST': { 'description': 'Bacino Media Veloce' }
        ,'BACINO_MM_SLOW': { 'description': 'Bacino Media Lenta'  }
    };

    const oMappingDettaglio = () => {
        let { pMMFastDays ,pMMSlowDays ,pMMFastPendDays } = oValues || oGetParams || { pMMFastDays: 0 ,pMMSlowDays: 0 ,pMMFastPendDays: 0 };
        let oDettaglio = {};

        // La chiave per accedere all'oggetto è il detaglio, ottenuto dalla URL
        switch (dettaglio) {

            case 'LineItems_Placements' : 
                oDettaglio = {
                     'sFieldList': 'PLACEMENT_ID,PLACEMENT_NAME'
                    ,'oGetParams': { getAlertLineItemPlacements: true
                                   , pLineItemId:                idDettaglio }
                    ,'oInfoPage' : { sNewPageName:               `LineItem [${ idDettaglio }] - Placements`
                                   , sNewPageSubName:            isDemo ? '' : localStorage.getItem(idDettaglio) }
                };
                break;
            
                case 'LineItems_Ad_Units' : 
                oDettaglio = {
                     'sFieldList': 'AD_UNIT_ID,AD_UNIT_NAME'
                    ,'oGetParams': { getAlertLineItemAdunits:   true
                                   , pLineItemId:               idDettaglio }
                    ,'oInfoPage' : { sNewPageName:              `LineItem [${ idDettaglio }] - Ad Units`
                                   , sNewPageSubName:           isDemo ? '' : localStorage.getItem(idDettaglio) }
                }
                break;
            
            case 'Placements_Graph' : 
                oDettaglio = {
                     'sFieldList': 'DATA_RIF,BACINO,BACINO_MM_FAST,BACINO_MM_SLOW,PERC_DECR'
                    ,'oGetParams': { getAlertPlacementGraph:    true
                                   , pPlacementId:              idDettaglio
                                   , pMMFastDays, pMMSlowDays, pMMFastPendDays }
                    ,'oInfoPage' : { sNewPageName:              `Placement [${ idDettaglio }] - Ad Units`
                                   , sNewPageSubName:           isDemo ? '' : localStorage.getItem(idDettaglio) }
                    ,'sGraphenabled'  : 'line'
                }
                break;
            
            case 'Placements_Ad_Unit' : 
                oDettaglio = {
                     'sFieldList': 'AD_UNIT_ID,AD_UNIT_NAME,FLAG_DOWNTREND'
                    ,'oGetParams': { getAlertAdunits:           true
                                   , pPlacementId:              idDettaglio
                                   , pMMFastDays, pMMSlowDays, pMMFastPendDays }
                    ,'oInfoPage' : { sNewPageName:              `Placement [${ idDettaglio }]`
                                   , sNewPageSubName:           isDemo ? '' : localStorage.getItem(idDettaglio)
                                   , pPlacementId:              idDettaglio }
                }
                break;
            
            case 'Placements_Ad_Unit_Graph' : 
                oDettaglio = {
                    'sFieldList': 'DATA_RIF,BACINO,BACINO_MM_FAST,BACINO_MM_SLOW,PERC_DECR'
                    ,'oGetParams': { getAlertPlacementADUNITSGraph: true
                                   , pPlacementId:                  localStorage.getItem(`Placement${idDettaglio}`)
                                   , AD_UNIT_ID:                    idDettaglio
                                   , pMMFastDays ,pMMSlowDays ,pMMFastPendDays }
                    ,'oInfoPage' : { ...oInfoPage
                                   , sNewPageName:                  `AD Unit [${ idDettaglio }]`
                                   , sNewPageSubName:               isDemo ? '' : localStorage.getItem(idDettaglio) }
                    ,'sGraphenabled'  : 'line'
                    ,'sAllarmeTooltip': `AD Unit legata al Placement [${oInfoPage.pPlacementId}] il cui bacino totale è in ${ 'oRow.FLAG_DOWNTREND' !== 'Y' ? 'crescita' : 'decrescita' } rispetto al suo storico` // !!! TODO Da fixare oRow.FLAG_DOWNTREND
                }
                break;
            
            case 'Ad_Unit_Graph' : 
                oDettaglio = {
                     'sFieldList': 'DATA_RIF,BACINO,BACINO_MM_FAST,BACINO_MM_SLOW,PERC_DECR'
                    ,'oGetParams': { getAlertADUnitGraph: true
                                   , pAdUnitId:           idDettaglio
                                   , pMMFastDays ,pMMSlowDays ,pMMFastPendDays }
                    ,'oInfoPage' : { sNewPageName:    `AD Unit [${ idDettaglio }]`
                                   , sNewPageSubName: isDemo ? '' : localStorage.getItem(idDettaglio) }
                    ,'sGraphenabled'  : 'line'
                }
                break;

            default:
                break;
        }
        
        return oDettaglio;
    };
    /* #endregion Mapping Obj */

    // Function per filtrare la tabella in base a odFilterTableDate
    const fFilterTable = ( arrayObject ) => {
        bDebug && console.log('fFilterTable: ', arrayObject[0]);
        return arrayObject.filter( dataObj => +dataObj['BEGIN_DATE'] >= +odFilterTableDate['dDataInizio'].format( 'YYYYMMDD' ) 
                                           && +dataObj['END_DATE']   <= +odFilterTableDate['dDataFine'].format( 'YYYYMMDD' ) );
    };

	// Function per formattare la tabella
    const getDetailTable = () => {
        bDebug && console.log('GetDetailTable');
        setLoading(true)

        /* #region Format */
        const
             sGAMurl               = config.GAM_URL
            ,formatZero            = (val) => val === 0 ? 'zero' : ''
            ,formatZeroSticky1     = (val) => val === 0 ? 'zero sticky1' : 'sticky1'
            ,formatZeroSticky3     = (val) => val === 0 ? 'zero sticky3alm' : 'sticky3alm'
            ,formatCheckBox        = (val, oRow) => {
                                        let obj = JSON.parse(oRow.ALERTS || "[]")?.find(o => o.A === id ) || {}
                                        return val && <Checkbox id        = { oRow?.LINE_ITEM_ID + '' }
                                                                checked   = { val === 'Y' }
                                                                onClick   = {(event) => { event.stopPropagation() }}
                                                                onChange  = {(event) => { setAlarmVerified(oRow) }}
                                                                title     = { formatDateCheckBox(obj?.['D']) }
                                                                className = { `checkbox-custom ${obj?.['U'] === kUser ? 'user-checked' : '' }`}/>}
            // JSON.parse(val)[0]['C']
            ,formatNum2dec         = (val) => typeof val === 'number' ? utils.formatNumberWithOptions(val, { nOuputDecimals: 2 }) : ''
            ,formatNum1decPerc     = (val) => { return !(+val) ? '' : `${ utils.formatNumberWithOptions(val, { nOuputDecimals: 1 }) } %` }
            ,formatNum0dec         = (val) => typeof val === 'number' ? utils.formatNumberWithOptions(val, { nOuputDecimals: 0 }) : ''
            ,formatImp0decPerc     = (val, oRow ) => `${utils.formatNumberWithOptions(val, { nOuputDecimals: 0 })} ${ asTypeInPerc.includes( oRow.LINE_ITEM_TYPE.trim() ) ? '%' : '' }`
            // ,formatDate            = (val) => moment(val, 'YYYYMMDD').format('D MMM YYYY')
            ,formatDateExcel       = (val) => moment(val, 'YYYYMMDD').format('DD/MM/YYYY')
            ,formatDateCheckBox    = (val) => !val ? '' : utils.formatDateTime(val, { input: 'YYYYMMDDHHmmss', output: 'D MMM YYYY HH:mm', useTimezone:true,fromNow: true })
            ,formatAccountDesc     = (val) => val === 'dvn' ? 'DVN' : utils.formatNamePage(val)
            ,formatLineItemLink    = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#delivery/line_item/detail/line_item_id=${ val }`}  target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
            ,formatPlacementLink   = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#inventory/placement/detail/placement_id=${ val }`} target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
            ,formatAdUnitLink      = (val, oRow ) => <a href={ sGAMurl + oRow.ACCOUNT_CODE +`#inventory/ad_unit/detail/ad_unit_id=${ val }`}     target='_blank' className='myExternalLinkID' rel='noreferrer'>{val}</a>
            ,formatUpAndDown       = (val) => val !== 'Y' ? <div className='trend'><TrendingUpIcon className={"textGreen"} /></div> : <div className='trend'><TrendingDownIcon className={"textRed"} /></div>
            // ,formatPercentage      = ( val, oRow ) => <div><AnagrafichePercentuale perc={oRow} /></div>
            ,formatLinkContending  = (val, oRow) =>  val > 0 ? <Tooltip title='Contending Line Items' placement='left' arrow followCursor>
                                        <Link to            = {`/anagrafica/${ oRow.ACCOUNT_DESC }/${ oRow.ACCOUNT_CODE }/LINE_ITEMS/${ oRow.LINE_ITEM_ID }?origin=${id}`} 
                                              onClick       = { () => { localStorage.setItem( 'sContendingName', oRow.LINE_ITEM_NAME )}}
                                              onContextMenu = { () => { localStorage.setItem( 'sContendingName', oRow.LINE_ITEM_NAME )}}
                                              className     = "link myShadow numberHover" >
                                            <span>{ val }</span>
                                        </Link></Tooltip> : 0
            ,formatLI_Placements   = ( val, oRow ) => val > 0 ? <Tooltip title='Vedi Placements' placement='left' arrow followCursor>
            { /* Icona da visualizzare nel primo livello di dettaglio di tutti i LineItems (1,2,3,4,5,6),
                 mi permette di ottenere il secondo livello di dettaglio con i Placements legati ad uno specifico LineItem */ }
                                        <Link to            = {`/allarme/${ id }/${ oRow.LINE_ITEM_ID }/LineItems_Placements`} 
                                              onClick       = { () => { localStorage.setItem(oRow.LINE_ITEM_ID, oRow.LINE_ITEM_NAME ); setLoading(true);}} 
                                              onContextMenu = { () => { localStorage.setItem(oRow.LINE_ITEM_ID, oRow.LINE_ITEM_NAME )}} 
                                              className     = 'link myShadow numberHover'>
                                            <span>{ utils.formatNumberWithOptions(val, { nOuputDecimals: 0 }) }</span>
                                        </Link></Tooltip> : 0
            ,formatLI_AdUnits      = ( val, oRow ) => val > 0 ? <Tooltip title='Vedi Ad Units' placement='left' arrow followCursor>
            { /* Icona da visualizzare nel primo livello di dettaglio di tutti i LineItems (1,2,3,4,5,6),
                 mi permette di ottenere il secondo livello di dettaglio con le Adunits direttamente legate ad uno specifico LineItem */ }
                                        <Link to            = {`/allarme/${ id }/${ oRow.LINE_ITEM_ID }/LineItems_Ad_Units`} 
                                              onClick       = { () => { localStorage.setItem(oRow.LINE_ITEM_ID, oRow.LINE_ITEM_NAME ); setLoading(true);}}
                                              onContextMenu = { () => { localStorage.setItem(oRow.LINE_ITEM_ID, oRow.LINE_ITEM_NAME )}}
                                              className     = 'link myShadow numberHover'>
                                            { utils.formatNumberWithOptions(val, { nOuputDecimals: 0 }) }
                                        </Link></Tooltip> : 0
            ,formatPlacementsGraph = ( val, oRow ) => <div className='link-Wrapper'><Tooltip title='Vedi Grafico' placement='left' arrow followCursor>
            { /* Icona da visualizzare nel primo livello di dettaglio di tutti i Placements (Allarm 7),
                 mi permette di ottenere il secondo livello di dettaglio con il Grafico di uno specifico Placement con lo spaccato giorni */ }
                                        <Link to            = {`/allarme/${ id }/${ val }/Placements_Graph`} 
                                              onClick       = { () => { localStorage.setItem(val, oRow.PLACEMENT_NAME ); setLoading(true);}}
                                              onContextMenu = { () => { localStorage.setItem(val, oRow.PLACEMENT_NAME )}}
                                              className     = 'link myShadow'>
                                            <SsidChartIcon />
                                        </Link></Tooltip></div>
            ,formatPlacements_AD   = ( val, oRow ) => <Tooltip title='Vedi AD Units' placement='left' arrow followCursor>
            { /* Icona da visualizzare nel primo livello di dettaglio di tutti i Placements (Allarm 7),
                 mi permette di ottenere il secondo livello di dettaglio con le Ad_Units legate ad uno specifico Placement*/ }
                                        <Link to            = {`/allarme/${ id }/${ val }/Placements_Ad_Unit`} 
                                              onClick       = { () => { localStorage.setItem(val, oRow.PLACEMENT_NAME ); setLoading(true);}}
                                              onContextMenu = { () => { localStorage.setItem(val, oRow.PLACEMENT_NAME )}}
                                              className     = 'link myShadow'>
                                            <div><DoubleArrowRightIcon /></div>
                                        </Link></Tooltip>
            ,formatPlacADSGraph    = ( val, oRow ) => <div className='link-Wrapper'><Tooltip title='Vedi Grafico' placement='left' arrow followCursor>
            { /* Icona da visualizzare nel secondo livello di dettaglio di tutti i Placements (Allarm 7),
                 mi permette di ottenere il terzo livello di dettaglio con il Grafico di una specifica Ad_unit con lo spaccato giorni */ }
                                        <Link to            = {`/allarme/${ id }/${ val }/Placements_Ad_Unit_Graph`} 
                                              onClick       = { () => { localStorage.setItem(`Placement${val}`, idDettaglio ); localStorage.setItem(val, oRow.AD_UNIT_NAME ); setLoading(true);}}
                                              onContextMenu = { () => { localStorage.setItem(`Placement${val}`, idDettaglio ); localStorage.setItem(val, oRow.AD_UNIT_NAME )}}
                                              className     = 'link myShadow'>
                                            <SsidChartIcon />
                                        </Link></Tooltip ></div>
            ,formatADUnitGraph = ( val, oRow ) => <div className='link-Wrapper'><Tooltip title='Vedi Grafico' placement='left' arrow followCursor>
            { /* Icona da visualizzare nel primo livello di dettaglio di AD UNits con bacino (Allarm 8),
                 mi permette di ottenere il secondo livello di dettaglio con il Grafico di una specifica AD UNit con lo spaccato giorni */ }
                                        <Link to            = {`/allarme/${ id }/${ val }/Ad_Unit_Graph`}
                                              onClick       = { () => { localStorage.setItem(val, oRow.AD_UNIT_NAME ); setLoading(true);}} 
                                              onContextMenu = { () => { localStorage.setItem(val, oRow.AD_UNIT_NAME )}} 
                                              className     = 'link myShadow'>
                                            <SsidChartIcon />
                                        </Link></Tooltip ></div>
            ,formatViewablyTarget = ( val, oRow ) => {
                // bDebug && console.log('sModifyTarget: "' + sModifyTarget + '" type ' + typeof sModifyTarget);
                // bDebug && console.log('LINE_ITEM_ID: "' + oRow.LINE_ITEM_ID + '" type ' + typeof oRow.LINE_ITEM_ID);
                // bDebug && console.log(sModifyTarget === ( oRow.LINE_ITEM_ID + '' ));
                
                return sModifyTarget === (( oRow.LINE_ITEM_ID || 0 ) + '' ) ? (<>

                    <FormControl sx={{ m: 1 }} variant="outlined">
                        <CustomCssTextField
                            id              ={ `${oRow.LINE_ITEM_ID}` }
                            key             ={ oRow.LINE_ITEM_ID }
                            label           ={ "" }
                            value           ={ vModifyContent }
                            onChange        ={ handleWriteModifyContent }
                            type            ="text"
                            size            ="small"
                            className       ="OutlinedInputNum"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <Tooltip title='Salva modifica' placement='bottom' arrow>
                                        <IconButton onClick={ fSetModifyTarget } className='myShadow iconButtonPadding'>
                                            <SaveOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                </InputAdornment>
                            }}
                        />
                    </FormControl></>)
                  : formatNum0dec( val )
            }
            ,iconCheckFilter     = <div className='iconWrap d-flex'><CheckDoubleIcon className={"textGrey"}/></div>
            ,iconChecked         = <div className='iconWrap d-flex'><CheckBoxFlagged className={"textGrey"}/></div>
            ,iconNotChecked      = <div className='iconWrap d-flex'><CheckBoxEmpty   className={"textGrey"}/></div>
            // ,iconCheckedUser     = <div className='iconWrap d-flex'><CheckBoxFlagged className={"textGrey user-checked"}/></div>
            // ,iconNotCheckedUser  = <div className='iconWrap d-flex'><CheckBoxEmpty   className={"textGrey user-checked"}/></div>
            // ,filterFunction      = ( val, valToCheck, id, kUser ) => {
            //     let valParse = JSON.parse(val || "[]")?.find(o => o.A === id ) || {};
            //     console.log('valParse?.[U]', valParse?.['U']);
            //     console.log('kUser', kUser);
            //     return valParse?.['C'] === valToCheck && valParse?.['U'] === kUser;
            // }
        ;
        /* #endregion */
        
        // Mapping table
        const omap = {
              'ACCOUNT_DESC': !( isDemo || env.includes('sole')  ) ? ({ name: 'ACCOUNT_DESC' , title: 'GAM Account'             , width: 90  , format: formatAccountDesc     , isUniqueKeyForRow: true } ) : null // eccezione per cliente sole24ore
            // , 'ALERTS'                   : { name: 'ALERTS'                    , title: iconCheckFilter              , width: 31  , format: formatCheckBox        , isUniqueKeyForRow: true                  , headerTooltip: 'Verificato' }
            , 'FLAG_VERIFICATO'          : { name: 'FLAG_VERIFICATO'           , title: iconCheckFilter              , width: 31  , format: formatCheckBox        , isUniqueKeyForRow: true                  , headerTooltip: 'Verificato'
                                                ,selectOptions: [
                                                    { label: ' '                ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                                                   ,{ label: iconChecked        ,value: 'Y' ,checkFunc: ( val ) => val === 'Y' }
                                                   ,{ label: iconNotChecked     ,value: 'N' ,checkFunc: ( val ) => val === 'N' }
                                                //    ,{ label: iconCheckedUser    ,value: 'Y' ,checkFunc: ( val, valToCheck ) => { return filterFunction(val, valToCheck, id, kUser)} }
                                                //    ,{ label: iconNotCheckedUser ,value: 'N' ,checkFunc: ( val, valToCheck ) => val.includes(valToCheck) }
                                               ]}
            , 'LINE_ITEM_ID'             : { name: 'LINE_ITEM_ID'              , title: 'Line Item ID'               , width: 100 , format: formatLineItemLink    , additionalClass: () => ' sticky5'      , isNum:   true , isUniqueKeyForRow: true  , filterOriginalValue: true }
            , 'LINE_ITEM_NAME'           : { name: 'LINE_ITEM_NAME'            , title: 'Line Item'                  , width: 350                                 , tooltip: true }
            , 'LINE_ITEM_TYPE'           : { name: 'LINE_ITEM_TYPE'            , title: 'Tipo'                       , width: 120                                 , tooltip: true }
            , 'ORDER_ID'                 : { name: 'ORDER_ID'                  , title: 'Ordine ID'                  , width: 100                                 , isNum:   true }
            , 'ORDER_NAME'               : { name: 'ORDER_NAME'                , title: 'Ordine'                     , width: 350                                 , tooltip: true }
            , 'AGENT_NAME'               : { name: 'AGENT_NAME'                , title: 'Agente'                     , width: 145                                 , tooltip: true }
            , 'PRIORITY'                 : { name: 'PRIORITY'                  , title: 'Priorità'                   , width: 50                                  , isNum:   true }
            , 'STATUS'                   : { name: 'STATUS'                    , title: 'Status'                     , width: 145                                 , tooltip: true }
            , 'BEGIN_DATE'               : { name: 'BEGIN_DATE'                , title: 'Data inizio'                , width: 90  , format: formatDateAll         , formatExcel: formatDateExcel }
            , 'END_DATE'                 : { name: 'END_DATE'                  , title: 'Data fine'                  , width: 90  , format: formatDateAll         , formatExcel: formatDateExcel }
            , 'TARGETED_DEVICE_NAMES'    : { name: 'TARGETED_DEVICE_NAMES'     , title: 'Devices'                    , width: 300                                 , tooltip: true }
            , 'CLICKS_DELIV'             : { name: 'CLICKS_DELIV'              , title: 'Clicks Delivered'           , width: 110 , format: formatNum0dec         , additionalClass: formatZero            , isNum:   true }
            , 'IMPRESSIONS_DELIV'        : { name: 'IMPRESSIONS_DELIV'         , title: 'Imps Delivered'             , width: 135 , format: formatNum0dec         , additionalClass: formatZero            , isNum:   true }
            , 'IMPRESSIONS_SCHEDULED'    : { name: 'IMPRESSIONS_SCHEDULED'     , title: 'Imps Scheduled'             , width: 135 , format: formatImp0decPerc     , additionalClass: formatZero            , isNum:   true }
            , 'VIDEOCOMPLETIONS_DELIV'   : { name: 'VIDEOCOMPLETIONS_DELIV'    , title: 'Video complet. Delivered'   , width: 135 , format: formatNum0dec         , additionalClass: formatZero            , isNum:   true }
            , 'VIDEOSTARTS_DELIV'        : { name: 'VIDEOSTARTS_DELIV'         , title: 'Video starts Delivered'     , width: 110 , format: formatNum0dec         , additionalClass: formatZero            , isNum:   true }
            , 'VIEWABLEIMPRESSIONS_DELIV': { name: 'VIEWABLEIMPRESSIONS_DELIV' , title: 'Viewable Imps Delivered'    , width: 110 , format: formatNum0dec         , additionalClass: formatZero            , isNum:   true }
            , 'TOTAL_ERROR_COUNT'        : { name: 'TOTAL_ERROR_COUNT'         , title: 'Errori Totali'              , width: 110 , format: formatNum0dec         , additionalClass: formatZero            , isNum:   true }
            , 'ERROR_PERC'               : { name: 'ERROR_PERC'                , title: 'Errori %'                   , width: 80  , format: formatNum1decPerc     , additionalClass: formatZero            , isNum:   true }
            , 'NUM_PLACEMENTS'           : { name: 'NUM_PLACEMENTS'            , title: 'Placements'                 , width: 74  , format: formatLI_Placements   , additionalClass: formatZeroSticky3     , isNum:   true , filterOriginalValue: true ,noExport: true }
            , 'NUM_AD_UNITS'             : { name: 'NUM_AD_UNITS'              , title: 'Ad Units'                   , width: 74  , format: formatLI_AdUnits      , additionalClass: formatZeroSticky1     , isNum:   true , filterOriginalValue: true ,noExport: true }
         // , 'ICONLINK_GETPLACEMENTS'   : { name: 'LINE_ITEM_ID'              , title: ''                           , width: 30  , format:    }
            
            , 'DELIVERY_ESTIMATED_PERC'  : { name: 'DELIVERY_ESTIMATED_PERC'   , title: 'Check Inventory'            , width: 80  , format: formatNum1decPerc      , additionalClass: formatZero           ,isNum:   true  ,headerTooltip: utils.mapTooltip.CheckInventory       }
            , 'ACTUAL_PROG_PERC'         : { name: 'ACTUAL_PROG_PERC'          , title: 'Progressivo attuale'        , width: 80  , format: formatNum1decPerc      , additionalClass: formatZero           ,isNum:   true  ,headerTooltip: utils.mapTooltip.Progressivoattuale   }
            , 'OBJ_PROG_PERC'            : { name: 'OBJ_PROG_PERC'             , title: 'Progressivo obiettivo'      , width: 80  , format: formatNum1decPerc      , additionalClass: formatZero           ,isNum:   true  ,headerTooltip: utils.mapTooltip.Progressivoobiettivo }
            , 'PACING'                   : { name: 'PACING'                    , title: 'Pacing'                     , width: 80  , format: formatNum1decPerc      , additionalClass: formatZero           ,isNum:   true  ,headerTooltip: utils.mapTooltip.Pacing               }
         // , 'INDICATORE'               : { name: 'LINE_ITEM_ID'              , title: 'Indicatore'                 , width: 100 , format: formatPercentage       , isNum:   true }
            , 'NUM_CONTENDING_LINE_ITEMS': ( isDemo || env.includes('sole') )
                                         ? { name: 'NUM_CONTENDING_LINE_ITEMS' , title: 'Contending'                 , width: 72  , format: formatLinkContending   , additionalClass: formatZero           , isNum:   true , notSortable: true } : null 
            

            , 'PLACEMENT_ID'             : { name: 'PLACEMENT_ID'              , title: 'Placement ID'               , width: 100 , format: formatPlacementLink   , isNum:   true , isUniqueKeyForRow: true  , filterOriginalValue: true }
            , 'PLACEMENT_NAME'           : { name: 'PLACEMENT_NAME'            , title: 'Placement'                  , width: 360                                 , tooltip: true }
            , 'PLACEMENT_NAMES'          : { name: 'PLACEMENT_NAMES'           , title: 'Placements'                 , width: 550                                 , tooltip: true }
            , 'AD_UNIT_ID'               : { name: 'AD_UNIT_ID'                , title: 'AD Unit ID'                 , width: 120 , format: formatAdUnitLink      , isNum:   true , isUniqueKeyForRow: true  , filterOriginalValue: true }
            , 'AD_UNIT_NAME'             : { name: 'AD_UNIT_NAME'              , title: 'AD Unit'                    , width: 550                                 , tooltip: true }
            , 'DATA_RIF'                 : { name: 'DATA_RIF'                  , title: 'Data'                       , width: 125 , format: formatDateAll         , formatExcel: formatDateExcel    , isNum: true , isUniqueKeyForRow: true }
            , 'BACINO'                   : { name: 'BACINO'                    , title: 'Bacino'                     , width: 100 , format: formatNum0dec         , additionalClass: formatZero     , isNum: true }
            , 'BACINO_MM_FAST'           : { name: 'BACINO_MM_FAST'            , title: 'Bacino MM Fast'             , width: 120 , format: formatNum0dec         , additionalClass: formatZero     , isNum: true ,headerTooltip: utils.mapTooltip.MMF}
            , 'BACINO_MM_SLOW'           : { name: 'BACINO_MM_SLOW'            , title: 'Bacino MM Slow'             , width: 120 , format: formatNum0dec         , additionalClass: formatZero     , isNum: true ,headerTooltip: utils.mapTooltip.MMS}
            , 'PERC_DECR'                : { name: 'PERC_DECR'                 , title: 'Decrescita %'               , width: 90  , format: formatNum1decPerc     , additionalClass: formatZero     , isNum: true }
            , 'FLAG_DOWNTREND'           : { name: 'FLAG_DOWNTREND'            , title: 'Down Trend'                 , width: 80  , format: formatUpAndDown       , filterOriginalValue: true       , notSortable: true }

            , 'ICON_GETPLACEMENTSGRAPH'  : { name: 'PLACEMENT_ID'              , title: 'Grafico'                    , width: 50  , format: formatPlacementsGraph , additionalClass: formatZero     , notSortable: true ,noExport: true }
            , 'ICONLINK_GETADUNITS'      : { name: 'PLACEMENT_ID'              , title: ''                           , width: 20  , format: formatPlacements_AD   , additionalClass: formatZero     , notSortable: true ,noExport: true }
            , 'ICON_GETPLACADSGRAPH'     : { name: 'AD_UNIT_ID'                , title: 'Grafico'                    , width: 50  , format: formatPlacADSGraph    , additionalClass: formatZero     , notSortable: true ,noExport: true }
            , 'ICON_GETADUNITGRAPH'      : { name: 'AD_UNIT_ID'                , title: 'Grafico'                    , width: 50  , format: formatADUnitGraph     , additionalClass: formatZero     , notSortable: true ,noExport: true }
            
            , 'VIEWABILITY'              : { name: 'VIEWABILITY'               , title: 'Viewability'                , width: 80  , format: formatNum2dec         , additionalClass: formatZero     , isNum:   true }
            , 'VIEWABILITY_TARGET'       : { name: 'VIEWABILITY_TARGET'        , title: 'Viewability Target'         , width: 130 , format: formatViewablyTarget  , isNum:   true                   , filterOriginalValue: true }
        };

        /* #region set_aoHeaders */

        // Array per contenere i nomi delle colonne splittati
        let asHeaders = sFieldList?.split(',');

        // Questa condizione mi serve perchè se ho getAlertDetailsParams sono nel primo livello di dettaglio dei LineItem e quindi mi serve mostrare la freccia per mostrare il secondo livello
        // if ( ['1','2','3','4','5','6'].includes(id) && oGetParams.getAlertDetailsParams ) {
        //     asHeaders.push('ICONLINK_GETPLACEMENTS');
        // }
        // 
        // if ( ['5'].includes(id) && oGetParams.getAlertDetailsParams ) {
        //     // asHeaders.push('INDICATORE');
        //     // !!! TODO Da spostare numPLA e NUM ADUNIT
        //     // asHeaders = asHeaders.filter( elem => !['BACINO_MM_FAST', 'BACINO_MM_SLOW', 'PEND_FAST_MM'].includes(elem));
        // }
        
        // if ( ['5'].includes(id) && oGetParams.getAlertDetailsParams ) {
        //     asHeaders.splice( 19, 0, 'INDICATORE');
        // }

        // Questa condizione mi serve perchè se ho getAlertDetailsParams sono nel primo livello di dettaglio dei Placements e quindi mi serve mostrare la freccia per mostrare il secondo livello e l'icona del grafico del Placement
        if ( ['7'].includes(id) && oGetParams.getAlertDetailsParams ) {
            asHeaders = asHeaders.filter( elem => !['BACINO_MM_FAST', 'BACINO_MM_SLOW', 'PEND_FAST_MM'].includes(elem));
            asHeaders.push('ICON_GETPLACEMENTSGRAPH');
            asHeaders.push('ICONLINK_GETADUNITS');
        }
        
        // Questa condizione mi serve perchè se ho getAlertAdunits sono nel secondo livello di dettaglio dei Placements e quindi mi serve l'icona del grafico per mostrare il terzo livello di dettaglio
        if ( ['7'].includes(id) && oGetParams.getAlertAdunits ) {
            asHeaders.push('ICON_GETPLACADSGRAPH');
        }

        if ( ['8'].includes(id) && oGetParams.getAlertDetailsParams ) {
            asHeaders = asHeaders.filter( elem => !['DATA_RIF', 'BACINO_MM_FAST', 'BACINO_MM_SLOW', 'PEND_FAST_MM'].includes(elem));
            asHeaders.push('ICON_GETADUNITGRAPH');
        }
        // console.log('asHeaders: ', asHeaders)
        // Array per contenere i nomi delle colonne e le relative proprietà di colonna
        const aoHeaders = asHeaders.map( sHeader => omap[sHeader] ).filter( s => s );
        // bDebug && console.log(sFieldList)
        /* #endregion */

        set_aoAllarmeDettaglioHeaders(aoHeaders);
        set_AllarmeDettaglio( isFilterDateTable ? [ ...fFilterTable(aoRecordsFromDB) ] : [ ...aoRecordsFromDB ]);
        // set_nAllarmeNumber(aoRecordsFromDB.length || 0);
        setLoading(false);
    };
      
    const getInfo = async () => {
        bDebug && console.log('getInfo');
        setLoading(true);
        set_dLastUpdate('');
        set_oNotifyOptions({ message: '' });
        try {   
            const response = await axios.get( config.API_URL + '/get', { headers, params: { getAlertsParams: true , nAlertId: id } });
            
            if ( response && ( response?.status === 200 ) && response?.data && response?.data?.aoRecords && response?.data?.aoRecords?.length ) {
                
                const aoRecords = response.data.aoRecords || [];
                let oRecord = aoRecords[0];
                if ( oRecord.TARGET === 'L' ) {
                    oRecord = { ...oRecord, FIELD_LIST: 'FLAG_VERIFICATO,' + oRecord.FIELD_LIST };
                }

                set_sAllarmeName(     oRecord.DESC_ALERT    || '' );
                set_sAllarmeType(     oRecord.TARGET        || '' );
                set_sAllarmeTooltip(  oRecord.NOTES         || '' );
                set_dLastUpdate(      oRecord.MAX_LAST_EXECUTION || '' );

                const oDefaultValueFromDB = JSON.parse( oRecord.DEFAULT_VALUES || '{}' ) || {};
                set_oValues({...oDefaultValueFromDB});
                set_boValueOnReload(!boValueOnReload); // set_boValueOnReload serve per lanciare la use effect solo in alcuni momenti e non ad ogni cambio di oValues

                if ( !dettaglio ) {
                    // console.log('NOT DETTAGLIO');
                    set_sGraphEnabled('');
                    set_sFieldList(       oRecord.FIELD_LIST || '' );
                    set_oInfoPage({  sNewPageSubName: '',         sNewPageName: ``  });
                    set_oGetParams({ getAlertDetailsParams: true, nAlertId: id      });
                    set_onReload(!onReload);
                } else {
                    set_bCaricaDettaglio(!bCaricaDettaglio);
                }
            } else if ( response?.data?.aoRecords?.length === 0 ) {
                setLoading(false);
            } else {
                throw new Error('Nessuna risposta');
            }
        } catch(err) {
            console.error('ERR 100: Failed GetAllarmeParams: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
            setLoading(false);
        }
    }

    const getAlarmDetailsFromDB = async ( oValuesParam ) => {
        bDebug && console.log('GetAlarmDetailsFromDB');
        setLoading(true);
        set_aoRecordsFromDB([]);
        // set_oNotifyOptions({ message: `` }); // !!! non settare a '' altrimenti non vedo le notifiche di modifica

        // Chiamata per ottenere i valori da inserire nella tabella
        try {
            // bDebug && console.log('oGetParams: ', oGetParams);
            // console.log('oValuesParam: ', oValuesParam);
            const params = { ...oGetParams, ...( oValuesParam && { oValues: (oValuesParam || {} ) } ) };
            bDebug && console.log('params: ', params);

            const response = await axios.get( config.API_URL + '/get', { headers, params } );

            if ( response && ( response?.status === 200 ) && response?.data?.aoRecords?.length ) {
                let aoActualRecords = response.data.aoRecords;
                if ( sAllarmeType === 'L' ) {
                    aoActualRecords = aoActualRecords.map( oRecord => ({ 
                        ...oRecord,
                        'FLAG_VERIFICATO': (JSON.parse(oRecord.ALERTS || "[]")?.find(o => o.A === id )?.['C'] === 'Y') ? 'Y' : 'N' }))
                }

                let newMinDateInizio = moment(utils.getMinObj( 'BEGIN_DATE', aoActualRecords)['BEGIN_DATE']) || moment();
                let newMaxDateInizio = moment(utils.getMaxObj( 'BEGIN_DATE', aoActualRecords)['BEGIN_DATE']) || moment();
                let newMinDateFine   = moment(utils.getMinObj( 'END_DATE',   aoActualRecords)['END_DATE'])   || moment();
                let newMaxDateFine   = moment(utils.getMaxObj( 'END_DATE',   aoActualRecords)['END_DATE'])   || moment();

                if ( isAlreadyFilter ) {
                    set_odFilterTableDate({ ...odFilterTableDate, minDateInizio: newMinDateInizio
                                        , maxDateInizio: newMaxDateInizio, minDateFine: newMinDateFine, maxDateFine: newMaxDateFine });
                } else {
                    set_odFilterTableDate({ dDataInizio: newMinDateInizio, minDateInizio: newMinDateInizio, maxDateInizio: newMaxDateInizio,
                                            dDataFine:   newMaxDateFine,   minDateFine:   newMinDateFine,   maxDateFine: newMaxDateFine });
                }
                // console.log([...aoActualRecords])
                // console.log([...aoActualRecords].length)

                set_aoRecordsFromDB([...aoActualRecords]);
                // set_aoRecordsFromDB([...aoActualRecords.map( oRecord => ({
                //       ...oRecord
                //       , ...( oRecord.DATA_RIF   && { DATA_RIF   : formatDateAll(oRecord.DATA_RIF)   } )
                //       , ...( oRecord.BEGIN_DATE && { BEGIN_DATE : formatDateAll(oRecord.BEGIN_DATE) } )
                //       , ...( oRecord.END_DATE   && { END_DATE   : formatDateAll(oRecord.END_DATE)   } )
                // }))]);
                set_isAlreadyFilter(true);

            } else if ( response?.data?.aoRecords?.length === 0 ) {
                setLoading(false);
            } else {
                throw new Error('Nessuna risposta');
            }

        } catch(err) {
            if ( oGetParams.getAlertDetailsParams ) {
                console.error('ERR 101: Failed GetAllarmeDettaglioLiv1: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
                setLoading(false);
            }  else {
                console.error('ERR 102: Failed GetAllarmeDettaglioLiv2', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' })
                setLoading(false);
            }
        }
    };

    const setAlarmVerified = async (obj) => {
        setLoading(true);
        try {
            // set_sModifyTarget( obj.LINE_ITEM_ID + '' );
            set_sVerifiedTarget( obj.LINE_ITEM_ID + '' );

            const params = {
                pLineItemId:    obj?.LINE_ITEM_ID || ""
               ,pAlertId:       id
               ,pAlertStatus:   (JSON.parse(obj?.ALERTS || "[]")?.find(o => o.A === id )?.['C'] === 'N') ? 'Y' : 'N'
               ,pAlertsJsonAll: obj?.ALERTS || "[{}]"
            };
            // console.log('params: ', params);

            const response = await axios.post( config.API_URL + '/set-alerts-verified', null, { headers, params } );
            const { nRetVal, vErrorMessageDesc } = response.data;

            if ( ( response.status === 200 ) && ( +nRetVal > 0 ) && ( !vErrorMessageDesc ) ) {
                set_oNotifyOptions({ message: `Operazione eseguita correttamente`, severity: 'success', timeout: 4 });
            } else {
                console.error(`Errore ${ vErrorMessageDesc || '' }`);
                set_oNotifyOptions({ message: <><span>Operazione fallita su LineItem {(obj?.LINE_ITEM_ID || '')}</span>
                                           <br/><span>{obj?.LINE_ITEM_NAME}</span>
                                           <br/><span>{ vErrorMessageDesc || '' }</span></>, severity:'error' });
            }
        } catch ( err ) {
            console.error('ERR 134: Failed', err);
            set_oNotifyOptions({ message: <><span>Operazione fallita su LineItem {(obj?.LINE_ITEM_ID || '')}</span><br/><span>{obj?.LINE_ITEM_NAME}</span></> , severity:'error' });
        }
        set_onReload(!onReload);
    }
    
    /* #region UseEffect */
    useEffect(() => {
        bDebug && console.log('UseEffect Iniziale dettaglio');
        getInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ dettaglio ]);

    useEffect( () => {
        // Mi serve una useEffect per forzare al bisogno il set di stati collegati a oMappingInput e basati su oValues
        bDebug && console.log('Richiamo OValueUseEffect');
        if ( boValueOnReload != null ) {
            // bDebug && console.log('oValues: ', oValues);
            // bDebug && console.log('OValueUseEffect');
            Object.keys(oMappingInputs).forEach( sInputID => {
                
                let   type         = oMappingInputs[sInputID].type;
                const label        = oMappingInputs[sInputID].label;
                const valOValueKey = oValues[sInputID] ? oValues[sInputID] : 0;
                // bDebug && console.log( sInputID + ' valOValueKey: ' + valOValueKey );
                // bDebug && console.log( sInputID + ' label: ' + label );
                // bDebug && console.log( sInputID + ' type: ' + type );

                if ( valOValueKey >= 0 && type === 'date') {
                    if ( label === 'Data inizio' || label === 'Dal giorno') {
                        set_dataPrima({ data: moment().subtract(valOValueKey, 'days'), diffDays : valOValueKey });
                        
                    } else if ( label === 'Data fine') {
                        set_dataDopo({ data: moment().add(valOValueKey, 'days'), diffDays : valOValueKey });
                    }
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ boValueOnReload ]);

    useEffect( () => {
        if ( onReload != null ) {
            bDebug && console.log('UseEffect OnReload');
            if ( oValues?.pDaysBefore < 0 || oValues?.pDaysBefore > 62 ) {
                // bDebug && console.log('pDaysBefore: ', oValues.pDaysBefore);
                set_oValues({ ...oValues, pDaysBefore: 0 });
                set_boValueOnReload(!boValueOnReload);
                set_oNotifyOptions({ message: `Warning: La Data selezionata non è conforme`, severity:'warning', timeout: 3 });
                setLoading(false);
            } else if ( oValues?.pDaysAfter < 0 || oValues?.pDaysAfter > 62 ) {
                // bDebug && console.log('pDaysBefore: ', oValues.pDaysAfter);
                set_oValues({ ...oValues, pDaysAfter: 0});
                set_boValueOnReload(!boValueOnReload);
                set_oNotifyOptions({ message: `Warning: La Data selezionata non è conforme`, severity:'warning', timeout: 3 });
                setLoading(false);

            } else if ( oValues?.pDays < 0 || oValues?.pDays > 62 ) {
                // bDebug && console.log('pDaysBefore: ', oValues.pDays);
                set_oValues({ ...oValues, pDays: 0});
                set_boValueOnReload(!boValueOnReload);
                set_oNotifyOptions({ message: `Warning: La Data selezionata non è conforme`, severity:'warning', timeout: 3 });
                setLoading(false);

            } else {
                // bDebug && console.log('Dentro UseEffect On Reload');
                // bDebug && console.log('oValues: ', oValues);
                if ( ( Object.keys(oGetParams).find( s => s.includes('Graph') && ( oGetParams[s] === true ))) || (!oGetParams.getAlertDetailsParams) ) {
                    bDebug && console.log('noValues')
                    getAlarmDetailsFromDB();
                } else {
                    getAlarmDetailsFromDB(oValues);
                }
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    // NOn ne son sicuro ma probabilmente questa serve per scatenare l'onReload quando siamo nel 2° e 3° liv di dettaglio (getAlertDetailsParams)
    useEffect( () => {
        bDebug && console.log('UseEffect oGetParams', oGetParams);
        if ( !oGetParams?.getAlertDetailsParams ) {
            bDebug && console.log('Dentro UseEffect oGetParams');
            set_onReload(!onReload);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ oGetParams ]);

    useEffect(() => {
        if ( dettaglio && oValues ) {
            let o = oMappingDettaglio();
            // let o = oMappingDettaglio()?.[dettaglio];
            bDebug && console.log(`oMappingDettaglio().[${dettaglio}]: `, o)
            o?.sAllarmeTooltip && set_sAllarmeTooltip( o.sAllarmeTooltip )
            o?.sGraphenabled   && set_sGraphEnabled(   o.sGraphenabled   )
            set_sFieldList( o?.sFieldList );
            set_oInfoPage(  o?.oInfoPage  );
            set_oGetParams( o?.oGetParams );
            // set_oGetParams( oMappingDettaglio()?.[dettaglio]?.oGetParams );
        } else {
            if ( onReload != null ) {
                bDebug && console.log('save me');
                set_sGraphEnabled('');
                set_isAlreadyFilter(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bCaricaDettaglio ])

    useEffect(() => {
        if ( sFieldList ) {
            // bDebug && console.log('isFilterDateTable? ', sFieldList.split(',').includes('LINE_ITEM_ID') && !asNoFilterDateTable.includes(id) )
            set_isFilterDateTable( sFieldList.split(',').includes('LINE_ITEM_ID') && !asNoFilterDateTable.includes(id) );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sFieldList ]);

    // UseEffect per intercettare il cambiamento di odFilterTableDate per scatenare il ripopolamento della tabella con i dati filtrati 
    useEffect(() => {
        if ( onReload != null && aoRecordsFromDB.length > 0 && isFilterDateTable ){
            // bDebug && console.log('Dentro UseEffect odFilterTableDate: ', odFilterTableDate)
            set_AllarmeDettaglio([...fFilterTable(aoRecordsFromDB)]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ odFilterTableDate ]);

    useEffect( () => {
        if ( onReload != null && aoRecordsFromDB.length > 0 ) {
            // bDebug && console.log('useEffect aoRecordsFromDB');
            // bDebug && console.log('vModifyContent: ', vModifyContent);
            getDetailTable();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ aoRecordsFromDB, sModifyTarget, vModifyContent ]);

    useEffect( () => {
        if ( loading === false && (sModifyTarget || sVerifiedTarget)) {
            bDebug && console.log('useEffectLoading');
            handleScroll(sModifyTarget || sVerifiedTarget);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ loading ]);
    /* #endregion UseEffect */

    /* #region Handler */
    const handleFilterTableDate = ({ value, label }) => {
        if ( value && value.isValid() ) {
            if ( label === 'dDataInizio' && ( value.format('YYYYMMDD') >= odFilterTableDate['minDateInizio'].format('YYYYMMDD')) && ( value.format('YYYYMMDD') <= odFilterTableDate['maxDateInizio'].format('YYYYMMDD')) && ( value.format('YYYYMMDD') <= odFilterTableDate['dDataFine'].format('YYYYMMDD'))) {
                set_odFilterTableDate({ ...odFilterTableDate, dDataInizio: value })
            } else if ( label === 'dDataFine' && ( value.format('YYYYMMDD') >= odFilterTableDate['dDataInizio'].format('YYYYMMDD')) && ( value.format('YYYYMMDD') >= odFilterTableDate['minDateFine'].format('YYYYMMDD')) && ( value.format('YYYYMMDD') <= odFilterTableDate['maxDateFine'].format('YYYYMMDD'))) {
                set_odFilterTableDate({ ...odFilterTableDate, dDataFine: value })
            }
        }
    };

    const handleDate = ({ value, label, oggi }) => {
        // Questa funzione scrive negli stati dataPrima e dataDopo controllando unicamente che diffDate sia un numero, altrimenti ritorna -1
        let datascelta =   moment(value);

        if ( moment(datascelta).format('DD/MM/YYYY') !== moment(oggi).format('DD/MM/YYYY') ) {
            if ( label === 'Data inizio' || label === 'Dal giorno' ) {
                let diffDate = moment(oggi).diff( datascelta, 'days') || -1;
                // console.log(` moment(${oggi}).diff( ${datascelta}, 'days'): `,  diffDate);
                set_dataPrima({ data: datascelta, diffDays: diffDate });
                
            } else if ( label === 'Data fine') {
                let diffDate = moment(datascelta).diff( oggi, 'days') + 1 || -1;
                set_dataDopo({ data: datascelta, diffDays: diffDate });

            } else {
                console.error(`ERR 103: Errore Imprevisto, la label non ha riscontro con il mappingInputs, contattare l'amministratore`)
                set_oNotifyOptions({ message: `Errore Imprevisto, contattare l'amministratore`, severity:'error' });
            }
        } else {
            ( label === 'Data fine') ?  set_dataDopo({ data: datascelta, diffDays: 0 }) : set_dataPrima({ data: datascelta, diffDays: 0 });
        }
    };

    const handleBlur = ({ label, sInputID, oggi, limiteMax, limiteMin }) => {
        // Questa funzione controlla se diffDays è > 0 e < 62 prima di settarlo in oValue, se non rispetta la condizione inserisce valori di default. Questa funzione non viene innescata se si preme invio dentro la DatePicker
        bDebug && console.log('HandleBlur', { label, sInputID });

        let diffDate;
        let warning = false;
        if ( label === 'Data inizio' || label === 'Dal giorno' ) {
            diffDate = dataPrima.diffDays;
            if ( diffDate < 0 ) {
                diffDate = 0;
                warning = true;
                set_dataPrima({ data: oggi, diffDays: 0 });
            } else if ( diffDate > 62) {
                diffDate = 62;
                warning = true;
                set_dataPrima({ data: limiteMin, diffDays: 62 });
            }
        } else if ( label === 'Data fine') {
            diffDate = dataDopo.diffDays;
            if ( diffDate < 0 ) {
                diffDate = 0;
                warning = true;
                set_dataDopo({ data: oggi, diffDays: 0 });
            } else if ( diffDate > 62 ) {
                diffDate = 62;
                warning = true;
                set_dataDopo({ data: limiteMax, diffDays: 62 });
            }
        }
        set_oValues({ ...oValues, [sInputID]: diffDate});
        set_focusDatePicker('');
        
        if (warning) {
            set_oNotifyOptions({ message: `Warning: La Data selezionata non è conforme`, severity:'warning', timeout: 3 });
        }
    };

    const handleScroll = (sModifyTarget) => {
        let element = document.getElementById(sModifyTarget);

        if (sModifyTarget && element) {
            element.scrollIntoView({ behavior: "auto", block: "center", inline: 'center' });
            sVerifiedTarget && set_sVerifiedTarget('')
        }
    };

    const handleWriteTextNum = (e) => {
        bDebug && console.log('Handlewrite');
        const { id, value } = e.target || {};
        const regEx = /^[0-9]*$/
        if ( regEx.test(value) || value === '' ) {
            set_oValues({ ...oValues, [id]: +value || 0 });
        }
    };

    const handleWriteModifyContent = (e) => {
        bDebug && console.log('HandleWriteModifyContent');
        // bDebug && console.log(e);
        const regEx = /^[0-9]*$/
        const { value } = e.target || {};
        // bDebug && console.log('Value: ', value);
        // bDebug && console.log(regEx.test(value));
        if ( regEx.test(value) || value === '' ) {
            if ( value > 99 ) {
                set_vModifyContent( 99 );
            } else if ( value < 0 ) {
                set_vModifyContent( 0 );
            } else {
                set_vModifyContent(value)
            }
        }
    };
    /* #endregion Handler */

    const fSetModifyTarget = async () => {
        bDebug && console.log('FSetModifyTarget');
        setLoading(true);
        set_oNotifyOptions({ message: `` });
        /* #region Features */
            /* switch (id) {
               case 10:
                   oSetParams = { modViewTarget: true, pLineItemId: sModifyTarget, pViewabilityTarget: vModifyContent }
                   break;
               default:
                   break;
            } */
        /* #endregion */

        // Chiamata per ottenere i valori da inserire nella tabella
        try {
            const params = { modViewTarget: true, pLineItemId: sModifyTarget, pViewabilityTarget: vModifyContent };
            // bDebug && console.log('params: ', params);

            const response = await axios.get( config.API_URL + '/get', { headers, params } );

            if ( response && ( response.status === 200 ) && (response?.data?.nRetVal === 1 ) ) {
                set_oNotifyOptions({ message: 'Aggiornamento del valore eseguito con successo', severity:'success', timeout: 2 });
                set_onReload(!onReload);
                // set_sModifyTarget(''); // non posso settare qui a '' perchè altrimenti non entro nell'handlescroll dell'useEffectOnReload

            } else {
                console.error(`Errore ${ response?.data?.vErrorMessageDesc }`)
                set_oNotifyOptions({ message: `Errore ${ response?.data?.vErrorMessageDesc }`, severity:'error' });
                setLoading(false);
            }

        } catch(err) {
            console.error('ERR 104: Failed fSetModifyTarget: ', err);
            set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            setLoading(false);
        }
    };

    const myKeyPress = (e) => {
        bDebug && console.log('MyKeyPress: ', e.key);
        // bDebug && console.log(e);
        if (e.key === 'Enter') {
            e.preventDefault();
            if ( sModifyTarget !== '') {
                fSetModifyTarget();
            } else {
                if ( focusDatePicker !== '' ) {
                    set_oValues({ ...oValues, [focusDatePicker] : (focusDatePicker === 'pDaysAfter' ? dataDopo.diffDays : dataPrima.diffDays )});
                }
                set_onReload(!onReload);
            }
        }
        
        if ( e.key === 'Escape' && sModifyTarget !== '' ) {
            e.preventDefault();
            set_sModifyTarget('');
            set_vModifyContent(null);
        }
    };

    const settingGraph = (field) => {
        if ( field !== sGraphEnabled ) {
            set_sGraphEnabled( field );
        }
    };

    // bDebug && console.log('oValues: ', oValues)
    // const condizioneFiltrati = () => {
    //     let asMappingField = Object.keys( oMappingInputs );
    //     let asOValues = Object.keys( oValues ).filter( elem => asMappingField.includes(elem));
    //     return asOValues.length > 0 && aoRecordsFromDB.length !== nAllarmeNumber;
    // }


    return (
        <div className='component-container alarmDetail' tabIndex={0} onKeyDown={ (e) => myKeyPress(e) }>
            <div className="component-card">
                {
                    loading
                    ? <CircleProgress />
                    : <>
                        <div className="component-options-wrapper-alarmDetail">
                            <div  className="component-options-wrapper-title">
                                {
                                    dettaglio
                                    ? <Link to={`/allarme/${id}`} className='link' >
                                          <Button className="backButton" variant="outlined" startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                              <span>allarme { id }</span>
                                          </Button>
                                      </Link>
                                    : <Link to="/allarmi" className='link' >
                                        <Button className="backButton" variant="outlined" startIcon={<DoubleArrowLeftIcon className='myIcon'/>} >
                                            <span>allarmi</span>
                                        </Button>
                                    </Link>
                                }
                                <div className="component-options-wrapper-title">
                                        <Tooltip title={sAllarmeTooltip} placement='bottom' arrow>
                                            <span className='titleAllarmeDettaglio'>
                                                {
                                                    oInfoPage.sNewPageName.length > 0 ? (
                                                        oInfoPage.sNewPageName)
                                                        : (<span>
                                                            {sAllarmeName}
                                                            {
                                                                // (id === '9' || id === '3' || id === '1') ?
                                                                    <span className='fsAllarmi'>Filtrati: {aoAllarmeDettaglio.length}</span>
                                                                    // : <span className='fsAllarmi'>
                                                                    //      Totali: {nAllarmeNumber || 0}
                                                                    //     {condizioneFiltrati() &&
                                                                    //         (id !== '9' || id !== '3' || id === '1') &&
                                                                    //         (
                                                                    //             <span className='msAllarmi'> <span className='hrAllarmi'>-</span>  Filtrati: {aoRecordsFromDB.length}</span>
                                                                    //         )}
                                                                    // </span>
                                                            }
                                                        </span>)
                                                }
                                            </span>
                                        </Tooltip>
                                    <span className='subTitleAllarmeDettaglio'>{
                                        oInfoPage.sNewPageName.length > 0 ? oInfoPage.sNewPageSubName : null
                                    }</span>
                                </div>
                            </div>
                            <div  className="component-options-wrapper-title">
                                {
                                    // DatePicker per filtro dinamico InPage senza ricaricare i dati da DB
                                    isFilterDateTable ?
                                      <div className="optionTab">
                                        <LocalizationProvider dateAdapter={AdapterMoment} key="dateInitial" >
                                          <Tooltip title={`Data minima: ${ odFilterTableDate['minDateInizio'].format('D MMM YYYY') } | Data massima: ${ odFilterTableDate['maxDateInizio'].format('D MMM YYYY') }`} placement='top'>
                                            <span className='d-flex jumpDateIcon'>
                                              { utils.jumpSegmentDate({ sPosition: 'before', dDateVal: odFilterTableDate['dDataInizio'], set_dDateVal: set_odFilterTableDate, dDateCompare: odFilterTableDate['minDateInizio'], dSegmentDateVal: odFilterTableDate['dDataFine'], complexObj: odFilterTableDate, dDateValKey: 'dDataInizio', dSegmentDateValKey: 'dDataFine' }) }
                                              <span className='pm-0'>
                                                  <DatePicker
                                                      id        = "dataInizio"
                                                      label     = "Dalla Data Inizio"
                                                      key       = "dataInizio"
                                                      format    = "DD/MM/YYYY"
                                                      views     = {['year', 'month', 'day']}
                                                      value     = { odFilterTableDate['dDataInizio'] }
                                                      minDate   = { odFilterTableDate['minDateInizio'] }
                                                      maxDate   = { odFilterTableDate['maxDateInizio'] }
                                                      onChange  = {(value) => handleFilterTableDate({ value: value, label: 'dDataInizio' })} //  set_odFilterTableDate({ ...odFilterTableDate, dDataInizio: value })
                                                      className = "storicoDPickerComponent mybordercolor"
                                                  />
                                              </span>
                                            </span>
                                          </Tooltip>
                                        </LocalizationProvider>
                                        <LocalizationProvider dateAdapter={AdapterMoment} key="dateFinish" >
                                          <Tooltip title={`Data minima: ${ odFilterTableDate['minDateFine'].format('D MMM YYYY') } | Data massima: ${ odFilterTableDate['maxDateFine'].format('D MMM YYYY') }`} placement='top'>
                                            <span className='d-flex jumpDateIcon'>
                                              <span className='pm-0'>
                                                  <DatePicker
                                                      id        = "dataFine"
                                                      label     = "Alla Data Fine"
                                                      key       = "dataFine"
                                                      format    = "DD/MM/YYYY"
                                                      views     = {['year', 'month', 'day']}
                                                      value     = { odFilterTableDate['dDataFine'] }
                                                      minDate   = { odFilterTableDate['minDateFine'] }
                                                      maxDate   = { odFilterTableDate['maxDateFine'] }
                                                      onChange  = {(value) => handleFilterTableDate({ value: value, label: 'dDataFine' })}
                                                      className = "storicoDPickerComponent mybordercolor"
                                                  />
                                              </span>
                                              { utils.jumpSegmentDate({ sPosition: 'after', dDateVal: odFilterTableDate['dDataFine'], set_dDateVal: set_odFilterTableDate, dDateCompare: odFilterTableDate['maxDateFine'], dSegmentDateVal: odFilterTableDate['dDataInizio'], complexObj: odFilterTableDate, dDateValKey: 'dDataFine', dSegmentDateValKey: 'dDataInizio'  }) }
                                            </span>
                                          </Tooltip>
                                        </LocalizationProvider>
                                      </div>
                                    : ''
                                }
                                {
                                    oInfoPage.sNewPageName.length > 0 ? <></> :
                                    <div className="optionTab">
                                        {
                                            Object.keys(oMappingInputs).map( sInputID => {
                                                let   type         = oMappingInputs[sInputID].type;
                                                const label        = oMappingInputs[sInputID].label;
                                                const text         = oMappingInputs[sInputID].text;
                                                const valOValueKey = ( oValues || {} )[sInputID];

                                                if ( !(isNaN(valOValueKey)) && type === 'date') {
                                                    
                                    // DatePiker per filtro non dinamico, per aggiornare i dati è necessario ricaricare la pagina tramite l'apposito button Reload
                                                    const oggi = moment();
                                                    const limiteMax = moment().add(62, 'days');
                                                    const limiteMin = moment().subtract(62,'days');

                                                    return <LocalizationProvider dateAdapter={AdapterMoment} key = { sInputID }>
                                                        <Tooltip 
                                                            key={`${sInputID} tooltip` } 
                                                            title={
                                                                  label === 'Data inizio' ? `Data minima: ${limiteMin.format('DD/MM/YYYY')} | Data massima: ${moment().format('DD/MM/YYYY')}` 
                                                                : label === 'Data fine'   ? `Data minima: ${moment().format('DD/MM/YYYY')} | Data massima: ${limiteMax.format('DD/MM/YYYY')}` 
                                                                : label === 'Dal giorno'  ? `Data minima: ${limiteMin.format('DD/MM/YYYY')} | Data massima: ${moment().format('DD/MM/YYYY')}` 
                                                                : '' } 
                                                            placement='top' 
                                                            arrow
                                                        >
                                                            <span className={`TextFieldDate d-flex jumpDateIcon ${id === '9' && 'TextField9'}`} onFocus={ () => set_focusDatePicker(sInputID) } onBlur={ () => handleBlur({ label, sInputID, oggi, limiteMax, limiteMin })}>
                                                                    { (label === 'Data inizio') &&
                                                                    <button className={`pm-0 ${dataPrima.diffDays > 61 || dataDopo.diffDays === 0 ? 'hiddenButton' : '' }`} onClick={() => {
                                                                        const oggi = moment()
                                                                        let valuePrima = dataPrima.data;
                                                                        let valueDopo =  dataDopo.data;
                                                                        if (dataPrima.diffDays < 62 && dataDopo.diffDays >= 1){
                                                                             valuePrima  =  moment(dataPrima.data).subtract(1, 'days')
                                                                             valueDopo   =  moment(dataDopo.data).subtract(1, 'days')
                                                                        } else if (dataPrima.diffDays >= 61 && dataDopo.diffDays <= oggi) {
                                                                            valuePrima = moment().subtract(62, 'days');
                                                                            valueDopo = oggi
                                                                        }
                                                                        handleDate({ value: valuePrima, label: 'Data inizio', oggi  });
                                                                        handleDate({ value: valueDopo , label: 'Data fine'  , oggi  });
                                                                      }}>
                                                                    
                                                                    <ArrowLeft />
                                                                    </button>
                                                                    }

                                                                {(label === 'Dal giorno') &&
                                                                    <button className={`pm-0 ${dataPrima.diffDays > 61 && 'hiddenButton'}`} onClick={() => {
                                                                        let valuePrima = dataPrima.data;
                                                                        if (dataPrima.diffDays < 62 ) {
                                                                            valuePrima = moment(dataPrima.data).subtract(1, 'days')
                                                                        } else if (dataPrima.diffDays >= 61 ) {
                                                                            valuePrima = moment().subtract(62, 'days');
                                                                        }
                                                                        handleDate({ value: valuePrima, label: 'Dal giorno'});
                                                                    }}>

                                                                        <ArrowLeft />
                                                                    </button>
                                                                }
                                                                
                                                                <DatePicker
                                                                    id          = { sInputID }
                                                                    label       = { label }
                                                                    format      = "DD/MM/YYYY"
                                                                    views       = {['year', 'month', 'day']}
                                                                    value       = { label === 'Data fine' ? dataDopo.data : dataPrima.data }
                                                                    // minDate     = { label === 'Data fine' ? oggi : limiteMin }
                                                                    // maxDate     = { label === 'Data fine' ? limiteMax : oggi }
                                                                    onChange    = {( value ) => { handleDate({ value, label, oggi })}}
                                                                    slotProps   = {{ textField: { variant:    'outlined' } }}
                                                                    className   = 'TextFieldDate'
                                                                   
                                                                />
                                                                 {label === 'Data fine' &&
                                                                       <button  className={`pm-0 ${dataPrima.diffDays === 0 || dataDopo.diffDays === 62 ? 'hiddenButton' : '' }`} onClick={() => {
                                                                        const oggi = moment()
                                                                        let valPrima = dataPrima.data;
                                                                        let valDopo  = dataDopo.data;
                                                                        if ( dataDopo.diffDays < 62 && dataPrima.diffDays > 0  ) {
                                                                            valDopo  =  moment(dataDopo.data).add(1, 'days');
                                                                            valPrima =  moment(dataPrima.data).add(1, 'days');
                                                                        } else if (dataPrima.diffDays < 0 && dataDopo.diffDays >= 62) {
                                                                             valPrima = oggi;
                                                                             valDopo  = moment().add(62, 'days');
                                                                        }
                                                                        handleDate({ value: valPrima, label: 'Data inizio', oggi  });
                                                                        handleDate({ value: valDopo , label: 'Data fine', oggi });
                                                                      }}>
                                                                         <ArrowRight />
                                                                    </button>
                                                                    }

                                                                {label === 'Dal giorno' &&
                                                                    <button className={`pm-0 ${ dataPrima.diffDays === 0 ? 'hiddenButton' : ''}`} onClick={() => {
                                                                        let valPrima = dataPrima.data;
                                                                        if (dataPrima.diffDays > 0) {
                                                                            valPrima = moment(dataPrima.data).add(1, 'days');
                                                                        } else if (dataPrima.diffDays < 0 ) {
                                                                            valPrima = moment();
                                                                        }
                                                                        handleDate({ value: valPrima, label: 'Dal giorno'});
                                                                    }}>
                                                                        <ArrowRight />
                                                                    </button>
                                                                }
                                                            </span>
                                                        </Tooltip>
                                                    </LocalizationProvider>
                                                
                                                } else if ( !(isNaN(valOValueKey)) && type === 'text' ) {
                                                    const val = +valOValueKey + '';

                                                    return val && (
                                                        <CustomCssTextField
                                                            id              = { sInputID }
                                                            key             = { sInputID }
                                                            label           = { label }
                                                            value           = { val }
                                                            onChange        = { handleWriteTextNum }
                                                            InputLabelProps = {{ shrink: true }}
                                                            tooltiptext     = { text }
                                                            variant         = "outlined"
                                                            type            = "text"
                                                            size            = "small"
                                                            className       = "TextFieldNum"
                                                        />
                                                    )
                                                } else if ( !(isNaN(valOValueKey)) && type === 'textNum' ) {
                                                    const val = +valOValueKey + '';
                                                    return val && <>
                                                        <CustomCssTextField
                                                            autoComplete    = "off"
                                                            id              = { sInputID }
                                                            key             = { sInputID }
                                                            label           = { label }
                                                            value           = { val }
                                                            onChange        = { handleWriteTextNum }
                                                            InputLabelProps = {{ shrink: true }}
                                                            variant         = "outlined"
                                                            type            = "text"
                                                            size            = "small"
                                                            className       = "TextFieldNum Pippo"
                                                        />
                                                        <IconButton className="addNewAdjustButton" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ handleWriteTextNum }> { /* !!! */ }
                                                            <SaveOutlinedIcon />
                                                        </IconButton>
                                                    </>
                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </div>
                                }
                                { Object.keys(oGetParams).find( s => s.includes('Graph') && ( oGetParams[s] === true ) ) &&

                               
                                    <ButtonGroup variant="outlined" disableElevation aria-label="outlined button group" className='buttonGroup'> { /*sx={{ borderRadius: '5px'}} */ }
                                        <Button className={`graphButton link myShadow ${ sGraphEnabled === 'line' ? 'activedButton' : '' }`} title="Mostra Grafico Lineare" variant="outlined" onClick={ () => settingGraph('line') } disableElevation>
                                            <SsidChartIcon />
                                        </Button>
                                        <Button className={`graphButton link myShadow ${ sGraphEnabled === 'bar' ? 'activedButton' : '' }`} title="Mostra Grafico a Barre" variant="outlined" onClick={ () => settingGraph('bar') } disableElevation>
                                            <LeaderboardIcon />
                                        </Button>
                                        <Button className={`graphButtonLast link myShadow ${ sGraphEnabled === '' ? 'activedButton' : '' }`} title="Mostra Tabella" variant="outlined" onClick={ () => settingGraph('') } disableElevation>
                                            <TableChartIcon />
                                        </Button>
                                    </ButtonGroup>
                                
                                }
                                <IconButton className="reloadButton link myShadow" sx={{ borderRadius: '5px'}} variant="outlined" onClick={ () => set_onReload(!onReload) }>
                                    <CachedIcon />
                                </IconButton>
                            </div>
                        </div>
                        
                        { // condizioni per la presenza dei dati
                            ( aoAllarmeDettaglio?.length && aoAllarmeDettaglioHeaders?.length && Array.isArray(aoAllarmeDettaglio))
                            ? sGraphEnabled
                                ? <Graph oGraphContent={{ sChartType: sGraphEnabled 
                                                         ,aoRows:  // aoAllarmeDettaglio
                                                            [...aoAllarmeDettaglio.map( oRecord => ({
                                                                          ...oRecord
                                                                        , ...( oRecord.DATA_RIF   && { DATA_RIF   : formatDateAll(oRecord.DATA_RIF)   } )
                                                                        , ...( oRecord.BEGIN_DATE && { BEGIN_DATE : formatDateAll(oRecord.BEGIN_DATE) } )
                                                                        , ...( oRecord.END_DATE   && { END_DATE   : formatDateAll(oRecord.END_DATE)   } )
                                                            }))]
                                                         ,asElementX: [ 'DATA_RIF' ] 
                                                         ,asElementY: [ 'BACINO' ,'BACINO_MM_FAST' ,'BACINO_MM_SLOW' ] 
                                                         ,oMappingField }} 
                                        className='grafico'/>
                                : <SimpleTable
                                className         = { loading || !( aoAllarmeDettaglio?.length && aoAllarmeDettaglioHeaders?.length && Array.isArray(aoAllarmeDettaglio)) || sGraphEnabled ? 'hiddenElement' : '' }
                                chiave            = { 'ALERTS' }
                                sTableDataType    = { 'ALERTS' }
                                aoRows            = { aoAllarmeDettaglio }
                                aoCols            = { aoAllarmeDettaglioHeaders }
                                noHeight          = { true }
                                // oHeadersOptions   = { { breakline: true } }
                                isEditable        = { ['10'].includes(id) }
                                oSortOptions      = { { isSortable: true } }
                                oExportOption     = { { sFileName: oInfoPage.sNewPageName.length > 0 ? oInfoPage.sNewPageName : sAllarmeName }}
                                oHeadersOptions   = { { tooltip: true, breakline: true } }
                                editFunction      = { ( oRow ) => {
                                    // bDebug && console.log( 'in editFunction, prima del set', oRow.LINE_ITEM_ID );
                                    // bDebug && console.log(( oRow.LINE_ITEM_ID + '' ) === sModifyTarget );
                                    setLoading(true);
                                    if ( ( oRow.LINE_ITEM_ID + '' ) === sModifyTarget ) {
                                        set_sModifyTarget('');
                                        set_vModifyContent(null);
                                    } else {
                                        set_sModifyTarget( oRow.LINE_ITEM_ID + '' );
                                        set_vModifyContent( oRow.VIEWABILITY_TARGET );
                                    }
                                } }
                            />
                                
                            : <div className="component-options-wrapper-alarmNoRecord"><FolderOffIcon />&nbsp;Nessun risultato trovato</div>
                        } 
                    </>
                }
            </div>
        </div>
    )
};


export default AllarmeDettaglionew;