import React, { useContext, useState, useEffect }   from 'react';
import { useNavigate }                   from 'react-router-dom';
import Input                             from '@mui/material/Input';
import FormControl                       from '@mui/material/FormControl';
import Button                            from '@mui/material/Button';
import IconButton                        from '@mui/material/IconButton';
import FormHelperText                    from '@mui/material/FormHelperText';
import InputAdornment                    from '@mui/material/InputAdornment';
import './ChangePassword.css';
import axios                             from 'axios';
import config                            from '../../config.js';
import CircleProgress                    from '../../components/CircleProgress/CircleProgress.tsx';
import utils                             from '../../util/CommonUtilities.js';
import { AuthContext }                   from '../../contexts/authContext';
import { AppContext }                    from '../../App.js';

import { KeyIcon
       , VisibilityIcon
       , VisibilityOffIcon }             from '../../assets/icons/icons.js';

// const regexPasswordValidator = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d£€§ùòàèìç°é!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;


const ChangePassword = () => { 

    const [ loading,              setLoading              ] = useState(false);
    const [ oldPassword,          setOldPassword          ] = useState("");
    const [ newPassword,          setNewPassword          ] = useState("");
    const [ confirmPassword,      setConfirmPassword      ] = useState("");
    const [ oldPasswordCheck,     setOldPasswordCheck     ] = useState(true);
    const [ newPasswordCheck,     setNewPasswordCheck     ] = useState(true);
    const [ confirmPasswordCheck, setConfirmPasswordCheck ] = useState(true);
    const [ oldPasswordShow,      setOldPasswordShow      ] = useState(false);
    const [ newPasswordShow,      setNewPasswordShow      ] = useState(false);
    const [ confirmPasswordShow,  setConfirmPasswordShow  ] = useState(false);
    const [ btnSubmitCheck,       setBtnSubmitCheck       ] = useState(false);
    const sHelperPswAccordant = 'Password non conforme';
    const sHelperPswEqual     = 'Le due password non coincidono';

    const navigate = useNavigate();
    const auth = useContext(AuthContext)
    const { set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext);

    useEffect(() => {
        set_dLastUpdate('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(()=>{
        setOldPasswordCheck(utils.regexPasswordValidator.test( oldPassword ));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[oldPassword]);

    useEffect(()=>{
        setNewPasswordCheck(utils.regexPasswordValidator.test( newPassword ));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newPassword]);

    useEffect(()=>{
        setConfirmPasswordCheck(utils.regexPasswordValidator.test( confirmPassword ));
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[confirmPassword]);

    const isErrorPasswordFormat = (psw, field) => {
        let retVal = true
        psw.trim();
        if(psw !== ""){
            if ( field === 'confirm') {
                if ( newPassword === confirmPassword ) {
                    retVal = !confirmPasswordCheck;
                }
            } else if ( field === 'newpsw') {
                retVal = !newPasswordCheck;
            } else if ( field === 'oldpsw') {
                retVal = !oldPasswordCheck;
            } else {
                retVal = true;
            }
        }else{
            retVal = false;
        }
        return retVal;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (e.key === 'Enter') {
            e.preventDefault();
            if ( btnSubmitCheck ) {
                changePSW();
            };
        };
    };

    const changePSW = async (event) => {
        event.preventDefault();
        setLoading(true)
        if ( newPassword !== oldPassword ) {    
            let response = {};
            try {
                    response = await axios.post(
                    config.API_URL + '/change-password'
                    , null
                    , {
                        headers: {
                            'Accept': 'application/json',
                            'Authorization': `Bearer ${auth.sessionInfo.accessToken}`
                        },
                        params  : { oldPassword, newPassword, email: localStorage.getItem('userEmail') }
                    }
                )
                
                if (response.status === 200 && !response.data.message ) {
                    navigate('/');
                    set_oNotifyOptions({ message: 'Cambio Password eseguito con successo', severity:'success', timeout: 2 });
                } else {
                    let messageError = response?.data?.message;
                    console.error(`ERR 136: ${messageError}`)
                    set_oNotifyOptions({ message: messageError, severity:'error', cognito: true });
                }

            } catch (err) {
                console.error('ERR 135: Failed to ChangePassword: ', err);
                set_oNotifyOptions({ message: `${err.message || 'Errore imprevisto' }`, severity:'error' });
            }
        } else {
            console.error('ERR: Fallimento nel Cambio Password: Password Nuova === Password Precedente')
            set_oNotifyOptions({ message: 'Cambio Password Errato: Le Password coincidono', severity:'warning', timeout: 3 });
        }
        setLoading(false)
    }

    useEffect(() => {
        const control = ( oldPassword && oldPasswordCheck ) && ( newPassword && newPasswordCheck ) && ( confirmPassword && confirmPasswordCheck ) && ( newPassword === confirmPassword ) && ( oldPassword !== newPassword );
        setBtnSubmitCheck(control);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ oldPassword, oldPasswordCheck, newPassword, newPasswordCheck, confirmPassword, confirmPasswordCheck ])

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
      <div className="component-container change-password">
        <div className="component-card flexy">
        { loading && <CircleProgress /> }
            <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3 change-password-container">
                <div className="app-login-main-content">
                    <div className="app-login-content-noAuth">
                    <div className="app-login-form" >
                        <form onSubmit={handleSubmit}>
                        <div className="form-group mb-3 flex-column">
                        <span className='criteriPass grey  '>Password minimo 8 caratteri, 1 maiuscolo, 1 minuscolo, <br />1 numero, 1 simbolo</span>
                        <br/>
                            <FormControl className="login-credentials-wrapper full-width">
                                <Input
                                    name            = "oldPassword"
                                    id              = "oldPassword"
                                    placeholder     = "Vecchia password"
                                    value           = { oldPassword }
                                    type            = { oldPasswordShow ? 'text' : 'password'}
                                    onChange        = { e => { setOldPassword(e.target.value); } }
                                    error           = { isErrorPasswordFormat(oldPassword, 'oldpsw') }
                                    spellCheck      = { false }
                                    startAdornment  = {
                                        <InputAdornment position="start">
                                            <KeyIcon className={"noHover textDark"}/>
                                        </InputAdornment>
                                    }
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={ e => { setOldPasswordShow(!oldPasswordShow); } }
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                { oldPasswordShow ? <VisibilityIcon className={"textDark"}/> : <VisibilityOffIcon className={"textDark"}/> }
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="standard-password-helper-text" className='helperText' >{ oldPassword && !oldPasswordCheck ? sHelperPswAccordant : '' }</FormHelperText>
                            </FormControl>
                            <FormControl className="login-credentials-wrapper full-width">
                                <Input
                                    name            = "newPassword"
                                    id              = "newPassword"
                                    placeholder     = "Nuova password"
                                    value           = { newPassword }
                                    type            = { newPasswordShow ? 'text' : 'password' }
                                    onChange        = { e => { setNewPassword(e.target.value); } }
                                    error           = { isErrorPasswordFormat(newPassword, 'newpsw') }
                                    spellCheck      = { false }
                                    startAdornment  = {
                                        <InputAdornment position="start">
                                            <KeyIcon className={"noHover textDark"}/>
                                        </InputAdornment>
                                    }
                                    endAdornment    = {
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={ e => { setNewPasswordShow(!newPasswordShow); } }
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                { newPasswordShow ? <VisibilityIcon className={"textDark"}/> : <VisibilityOffIcon className={"textDark"}/> }
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="standard-password-helper-text" className='helperText' >{ newPassword && !newPasswordCheck ? sHelperPswAccordant : '' }</FormHelperText>
                            </FormControl>
                            <FormControl className="login-credentials-wrapper full-width">
                                <Input
                                    name            = "confirmPassword"
                                    id              = "confirmPassword"
                                    placeholder     = "Conferma password"
                                    value           = { confirmPassword }
                                    type            = { confirmPasswordShow ? 'text' : 'password' }
                                    onChange        = { e => { setConfirmPassword(e.target.value); } }
                                    error           = { isErrorPasswordFormat(confirmPassword, 'confirm') }
                                    spellCheck      = { false }
                                    startAdornment  = {
                                        <InputAdornment position="start">
                                            <KeyIcon className={"noHover textDark"}/>
                                        </InputAdornment>
                                    }
                                    endAdornment    = {
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={ e => { setConfirmPasswordShow(!confirmPasswordShow); } }
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                { confirmPasswordShow ? <VisibilityIcon className={"textDark"}/> : <VisibilityOffIcon className={"textDark"}/> }
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText id="standard-password-helper-text" className='helperText' >
                                                    { 
                                                        ( confirmPassword && !confirmPasswordCheck ) ? 
                                                            sHelperPswAccordant 
                                                            : (confirmPassword && ( confirmPassword !== newPassword ) ) ?
                                                                sHelperPswEqual
                                                                : '' }</FormHelperText>
                            </FormControl>
                        </div>
                        <div className="mb-3 d-flex align-items-center justify-content-between submit-btn-wrapper" style={{marginTop: 40}}>
                            <Button type="button" className="text-uppercase submit-btn" variant="outlined" onClick={ changePSW } disabled={!btnSubmitCheck}>
                                Cambia Password
                            </Button>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
}

export default ChangePassword;
