import React    from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import moment   from 'moment';
import 'moment/locale/it';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';

// ----- popup per le notifiche (positive e negative) ----- 
// severity: 'error' | 'warning' | 'info' | 'success'
export function SnackAlert( { oNotifyOptions } ){
    let { severity = 'error', message, timeout, handleCloseAlert, link, cognito } = oNotifyOptions;
    if (!['error', 'warning', 'info', 'success'].includes(severity)) {
        severity = 'info';
    }

    let cognitoResponse = {
        "InvalidPasswordException" : " Password non valida ",
        "LimitExceededException"   : " Limite di tentativi superato ",
        "NotAuthorizedException"   : " Utente non abilitato o password errata ",
        "TooManyRequestsException" : " Troppe richieste ",
        "UserNotFoundException"    : " Utente non trovato ",
        "UserNotConfirmedException": " Utente non confermato "
    };

    return (
        !message
        ? null
        : <Snackbar
            open             = { true }
            className        = "snackAlert"
            anchorOrigin     = { { vertical: 'top', horizontal: 'center' } }
            autoHideDuration = { ( +timeout > 0 ) ? ( +timeout * 1000 ) : null }
            onClose          = { handleCloseAlert }
        >
            <MuiAlert elevation={6} variant="filled" onClose={ handleCloseAlert } severity={ severity }>
                {`[${moment().format('HH:mm')}] `} 
                { cognito 
                    ? cognitoResponse[message] || 'Errore imprevisto' 
                    : message 
                } 
                { link ? link : <></>} 
            </MuiAlert>
        </Snackbar>
    )
}

/* ----- esempio di utilizzo -----

    const handleCloseAlert    = (event, reason) => {
        // if (reason === 'clickaway') { return; }
        set_oNotifyOptions({ message: '' });
    };
    
    { ----- notifiche ----- }
    <SnackAlert oNotifyOptions={ { ...oNotifyOptions, handleCloseAlert } } />

    set_oNotifyOptions({ message, severity  });

*/
