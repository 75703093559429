import React, {
     useState
    ,useEffect
    ,useContext
}                                from 'react'; 
import { Link }                  from 'react-router-dom';
import axios                     from 'axios';
import moment                    from 'moment';
import                           'moment/locale/it';

import                           './Allarmi.css';
import config                    from '../../config.js';
import { AuthContext }           from '../../contexts/authContext';
import { AppContext }            from '../../App.js';
import { SimpleTable }           from '../../components/SimpleTable/SimpleTable';
import CircleProgress            from '../../components/CircleProgress/CircleProgress.tsx';
import { CustomCssTextField
        ,CustomMaxWidthTooltip } from '../../components/CustomCSS/CustomCSS.tsx';
import utils                     from '../../util/CommonUtilities';

import {
     CancelIcon
    ,CircleIcon
    ,CheckIcon
    ,DoDisturbIcon
    ,DoubleArrowRightIcon
    ,EmailIcon
    ,FolderOffIcon
    ,GearIcon
    ,InfoIcon
    ,SupervisorAccountIcon 
    ,NotSupervisorAccountIcon }                  from '../../assets/icons/icons.js';

import { Button
        // ,Checkbox
        ,Chip }                  from '@mui/material';
import { DialogTitle  
        ,DialogContent       
        ,DialogActions
        ,Dialog
        ,Tooltip
        ,Typography }            from '@mui/material';
import { Autocomplete }          from '@mui/material';

moment.locale('it');
const sPrefix       = "L'email verrà inviata solamente se l'allarme è Attivo ed è selezionato l'invio email"
const oRegroup      = { lb: "Invio singolo",
                        tp: "Se selezionato l'allarme viene inviato per email singolarmente, non raggruppato con gli altri." }
const oWeekDays     = { lb: "Frequenza settimanale",
                        tp: "Selezionare i giorni della settimana per i quali verrà inviata l'email, sabato e domenica esclusi." }
const oFrequency    = { lb: "Giorni rispetto la data di partenza della campagna ( 0 )",
                        tp: "Selezionare i giorni prima o dopo la data di partenza della campagna (indicata come giorno 0) per i quali verrà inviata l'email, sabato e domenica sempre esclusi.",
                        tp1:"Questa condizione e quella relativa alla Frequenza settimanale sono indipendenti, non si escludono a vicenda.",
                        tp2:"E' sufficiente che un giorno rientri in una delle due condizioni perchè l'email venga inviata.",
                        tp3:`Se ad esempio non è selezionato il Lunedì,
                             ma viene selezionato il 5° giorno antecedente alla partenza della campagna
                             e il 5° giorno corrisponde esattamente al Lunedì,
                             l'email verrà inviata.`,
                        tp4:`Se invece viene selezionato il Lunedì,
                             ma non viene selezionato nessun giorno antecedente alla partenza della campagna,                            
                             l'email verrà inviata comunque ogni Lunedì.`}
const oAgent        = { lb: "Agenti",
                        tp: "Se selezionato l'email viene inviata anche agli agenti, ogni agente riceve solamente gli elementi a lui associati." }
const oRecipientFl  = { lb: "Lista di Distribuzione",
                        tp: "Se selezionato l'email viene inviata alla seguente lista." }
const TpRecipientRF = <React.Fragment>
                             {/* <Typography color="inherit">Lista di Distribuzione</Typography> */}
                             { oRecipientFl.lb + ': ' + oRecipientFl.tp }
                        <br/>{ "Per inserire un utente DIS24 premere sul bottone '+', dopodichè selezionare gli utenti e premere sul pulsante 'CONFERMA'." }
                        <br/>{ "Per inserire un utente ESTERNO digitare direttamente l'email nel campo e premere 'Invio'." }
                      </React.Fragment>


const Allarmi = (props) => {
    
    const
         isProd  = process.env.REACT_APP_ENV.includes('prod')
        ,isDemo  = process.env.REACT_APP_ENV.includes('demo')
        ,isAdmin = ( localStorage.getItem('flagAdmin') || '' ) === 'Y'
        ,aAlarmsToDisplay = (JSON.parse(localStorage.getItem('alarmsToDisplay') || "[{}]") ).map( o => Object.keys(o) ).flat(Infinity)
        ,[ reload           ,set_reload           ] = useState(null)
        ,[ loading          ,setLoading           ] = useState(true)
        ,[ aoUsers          ,set_aoUsers          ] = useState([])
        ,[ aoUsersTemp      ,set_aoUsersTemp      ] = useState([])
        ,[ aoAllarmi        ,set_aoAllarmi        ] = useState([])
        ,[ aoAllarmiHeaders ,set_aoAllarmiHeaders ] = useState([])
        ,[ oDialogOptions   ,set_oDialogOptions   ] = useState(null)
        ,[ oDialogRecipientOptions ,set_oDialogRecipientOptions ] = useState(null)
        ,[ sRECIPIENT_EMAIL , set_RECIPIENT_EMAIL  ] = useState('')
        ,[ asEmailsSelected , set_asEmailsSelected ] = useState([])
        ,auth    = useContext(AuthContext)
        ,headers = {
             'Accept'        : 'application/json'
            ,'Authorization' : `Bearer ${auth.sessionInfo.accessToken}`
        }
        ,{ set_oNotifyOptions, set_dLastUpdate } = useContext(AppContext)
        ,oMappingInputs = {
             pDaysBefore         :{ label:'Giorni prima da oggi' ,min:1 ,max:99         ,text: 'La data di partenza o di fine campagna deve essere superiore a questo valore' }
            ,pDaysAfter          :{ label:'Giorni dopo di oggi'  ,min:1 ,max:99         ,text: 'La data di partenza o di fine campagna deve essere inferiore a questo valore' }
            ,pStatusList         :{ label:'Status filtrati'                             ,text: 'Status della campagna interessati' }
            ,pImpDeliv           :{ label:'Imps Erogate'         ,min:1 ,max:9999999999 ,text: 'Numero minimo di Impressions Delivered per campagne con Scheduled espresso in percentuale' }
            ,pImpDelivPerc       :{ label:'Imps Erogate %'       ,min:1 ,max:99         ,text: 'Percentuale minima di Impressions Delivered per campagne con Scheduled espresso in numero assoluto' }
            ,pPlacementId        :{ label:'Placement Id'         ,min:1 ,max:9999999999 ,text: '' }
            ,pAdUnitId           :{ label:'AdUnit Id'            ,min:1 ,max:9999999999 ,text: '' }
            ,pPacingRif          :{ label:'% di delivery'        ,min:1 ,max:99         ,text: 'Vengono prese in considerazione solo le Lineitem al di sotto della percentuale che rappresenta il raggiungimento dell\'obiettivo di delivery' }
            ,pDaysGraph          :{ label:'Days Graph'           ,min:1 ,max:99         ,text: '' }
            ,pMMFastDays         :{ label:'MM Fast Days'         ,min:1 ,max:99         ,text: 'Numero di giorni interessati dalla media mobile veloce' }
            ,pMMSlowDays         :{ label:'MM Slow Days'         ,min:1 ,max:99         ,text: 'Numero di giorni interessati dalla media mobile lenta' }
            ,pMMFastPendDays     :{ label:'MM Fast Pending Days' ,min:1 ,max:99         ,text: '' }
            ,pDays               :{ label:'Dal giorno'           ,min:1 ,max:99         ,text: 'Numero di giorni, prima di oggi, per cui le Adunit hanno dato di storico a zero' }
            ,pViewability        :{ label:'Viewability'          ,min:1 ,max:99         ,text: '' }
            ,pViewabilityDefault :{ label:'Default Viewability'  ,min:1 ,max:99         ,text: '' }
            ,pErrorsPerc         :{ label:'% Errori'             ,min:1 ,max:99         ,text: '' }
        }
        ,iconAdminYes  = <div className='iconWrap'><SupervisorAccountIcon       className={"textGrey"}      /></div>
        ,iconAdminNo   = <div className='iconWrap'><NotSupervisorAccountIcon    className={"textLightGrey"} /></div>
        ,iconAttivo    = <div className='iconWrap'><CheckIcon                   className={"textGreen"}     /></div>
        ,iconDisattivo = <div className='iconWrap'><DoDisturbIcon               className={"textRed"}       /></div>
        ,iconV         = <div className='iconWrap'><CheckIcon                   className={"textGrey"}      /></div>
        ,iconX         = <div className='iconWrap'><CancelIcon                  className={"textLightGrey"} /></div>
        ,aoUsersHeaders = [
            { name: 'FULL_NAME'   ,title: 'Nome'   ,width: 190 ,tooltip: true }
           ,{ name: 'USERNAME'    ,title: 'Utente' ,width: 375 ,tooltip: true ,isUniqueKeyForRow: true }
           ,{ name: 'FLAG_ATTIVO' ,title: 'Attivo' ,width: 60  
                ,format: (val) => ( val === 'Y' ) ? iconAttivo : iconDisattivo
                ,selectOptions: [
                     { label: ' '           ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                    ,{ label: iconAttivo    ,value: 'Y' ,checkFunc: ( val ) => val === 'Y' }
                    ,{ label: iconDisattivo ,value: 'N' ,checkFunc: ( val ) => val === 'N' }
                ]
           }
           ,{ name: 'FLAG_ADMIN' ,title: 'Admin' ,width: 60      
               ,format: (val) => ( val === 'Y' ) ? iconAdminYes : iconAdminNo
               ,selectOptions: [
                    { label: ' '            ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                   ,{ label: iconAdminYes   ,value: 'Y' ,checkFunc: ( val ) => val === 'Y' }
                   ,{ label: iconAdminNo    ,value: 'N' ,checkFunc: ( val ) => val === 'N' }
               ]
           }
           ,{ name: 'FLAG_TRAFFICKER' ,title:'Trafficker' ,width: 60      
               ,format: (val) => ( val === 'Y' ) ? iconV : iconX
               ,selectOptions: [
                    { label: ' '            ,value: ''  ,checkFunc: () => true } // tutte le righe sono valide
                   ,{ label: iconV          ,value: 'Y' ,checkFunc: ( val ) => val === 'Y' }
                   ,{ label: iconX          ,value: 'N' ,checkFunc: ( val ) => val === 'N' }
               ]
           }
        ]
        ,isCheckedFunction       = oRow => oRow.FLAG_SELECTED  === 'Y'
        
        // ATTENZIONE - questa funzione viene passata a un componente figlio (SimpleTable)
        ,handleChangeCheck       = ( { forceChecked, oRow, onlyWithFLAG_SELECTED } ) => {
            // oRow è il riferimento ad un oggetto contenuto in aoRows
            if ( !onlyWithFLAG_SELECTED || ( onlyWithFLAG_SELECTED && oRow.FLAG_SELECTED ) ) {
                oRow.FLAG_SELECTED = ( forceChecked != null ? forceChecked : !isCheckedFunction( oRow ) ) ? 'Y' : 'N';
            }
            // quindi una volta modificato è sufficiente risalvare nello stato una copia (come nuovo array) di aoRows usando setAoRows
        }

        ,fCheckAll               = ( { bCheckAll, aoRows, setAoRows, onlyWithFLAG_SELECTED } ) => {
            // vengono selezionate TUTTE le righe, indipendentemente dai filtri per colonna
            aoRows.forEach( oRow => handleChangeCheck({ forceChecked: bCheckAll, oRow, onlyWithFLAG_SELECTED }) );
            setAoRows( [...aoRows] );
        }

        ,fCheckOne               = ( { isChecked, oRow, aoRows, setAoRows  } ) => {
            handleChangeCheck({ isChecked, oRow });
            setAoRows( [...aoRows] );
        }
        ,input           = 'YYYYMMDDHHmmss'
        ,formatDate      = (val) => !val ? 'N.D.'                : utils.formatDateTime( val, { input, output: 'D MMM YYYY HH:mm', useTimezone: true, fromNow: true })
        ,formatDateExcel = (val) => !val ? 'N.D.'                : utils.formatDateTime( val, { input, output: 'DD/MM/YYYY HH:mm', useTimezone: true })
    
    ;
    
    const getUsers = ( async () => {
        try {
            const response = await axios.get( config.API_URL + '/get', { headers, params : { 'getUsersParams': true } } );
            if ( response.status === 200 ) {
                const result = response.data;
                if ( result ) {
                    let aoRecords = result.aoRecords || [];
                    if ( isDemo ) {
                        set_aoUsers(aoRecords.map( (o,n) => ({ ...o, EMAIL_ADDRESS: n+'emaildemo@google.com', FULL_NAME: 'demo'+n, USERNAME: 'demo'+n }) ));
                    } else {
                        set_aoUsers(aoRecords);
                    }
                }
            } else {
                throw new Error();
            }
        } catch(err) {
            console.error('ERR 130: Failed GetUsers: ', err);
            set_oNotifyOptions({ message: `Caricamento Utenti fallito`, severity:'error' });
        }
    });
    
    const onClickSetAlarm = async ( event, oRow ) => {
        
        setLoading(true);
        set_oNotifyOptions({ message: '' });
        
        const
             asCssClasses  = [...( event?.target?.classList || [] )  ] 
            ,isEnabled     = asCssClasses.includes('enabled')
            ,isFlagEnabled = asCssClasses.includes('pFlagEnabled')
            ,isFlagEmail   = asCssClasses.includes('pFlagEmail')
            ,params        = {
                 pAlertId            : oRow.ID_ALERT
                ,pInputValues        : oRow.INPUT_VALUES         || ''
                ,pFlagEnabled        : isFlagEnabled ? ( isEnabled ? 'N' : 'Y' ) : oRow.FLAG_ENABLED
                ,pFlagEmail          : isFlagEmail   ? ( isEnabled ? 'N' : 'Y' ) : oRow.FLAG_EMAIL
                ,pDefaultEmail       : oRow.DEFAULT_EMAIL        || ''
                ,pWeekDaysEmail      : oRow.WEEK_DAYS_EMAIL      || ''
                ,pFrequencyEmail     : oRow.FREQUENCY_EMAIL      || ''
                ,pRecipientEmail     : oRow.RECIPIENT_EMAIL.split(',').filter(Boolean).toSorted().join(',') || ''
                ,pFlagRegroupEmail   : oRow.FLAG_REGROUP_EMAIL   || ''
                ,pFlagRecipientEmail : oRow.FLAG_RECIPIENT_EMAIL || ''
                ,pFlagAgentEmail     : oRow.FLAG_AGENT_EMAIL     || ''
            }
        ;
        
        try {
            const response = await axios.post( config.API_URL + '/set-alarm', null, { headers, params } );
            if ( ( response.status === 200 ) && response?.data ) {
                set_oNotifyOptions({
                     message:  `Aggiornato Allarme ${ 'A' + (oRow.ID_ALERT + '').padStart(2,'0') }: "${ oRow.DESC_ALERT }"`
                    ,severity: 'success'
                    ,timeout:  6
                });
            } else {
                throw new Error();
            }
        } catch(err) {
            console.error('ERR 100: Failed /set-alarm: ', err);
            set_oNotifyOptions({
                 message:  `Fallito aggiornamento Allarme ${ 'A' + (oRow.ID_ALERT + '').padStart(2,'0') }: "${ oRow.DESC_ALERT }"`
                ,severity: 'error'
            });
        }
        set_reload(!reload);
    }
    
    useEffect(() => {
        set_dLastUpdate('');
        set_oNotifyOptions({ message: '' });
    }, []);
    
    useEffect(() => {
        if ( !oDialogOptions ) {
            getUsers();
        }
    }, [oDialogOptions]);
    
    useEffect(() => {
        
        setLoading(true);
        
        ( async () => {
            try {
                
                const response = await axios.get( config.API_URL + '/get', { headers, params: { "getAlertsParams": true } } );
                
                if ( response.status === 200 ) {
                    const result = response.data;
                    if ( result ) {
                        const 
                             aoRecords = isAdmin 
                                         ? result.aoRecords 
                                         : result.aoRecords.filter( oRecord => (
                                             ['S','Y'].includes(oRecord.FLAG_ENABLED) 
                                             && aAlarmsToDisplay.includes( 'A' + (oRecord.ID_ALERT + '').padStart(2,'0') )
                                         ) ) || []
                            
                            ,formatLinkDettaglio = ( val, { ID_ALERT } ) => 
                                <Link to={`/allarme/${ID_ALERT}`} className='link'><DoubleArrowRightIcon /></Link>
                            ,formatNumElem       = ( val ) => 
                                <span className={`${ val > 0 ? ' textRed' : ' textGreen' }`} >
                                    { utils.formatNumberWithOptions(val) }
                                </span>
                        ;
                        if ( isDemo ) { aoRecords.forEach( (o,n) => { o.RECIPIENT_EMAIL = 'demo' + n; }); } // da migliorare per demo
                        set_dLastUpdate( aoRecords[0]?.MAX_LAST_EXECUTION || '' );
                        
                        set_aoAllarmiHeaders([
                        
                isAdmin &&   { name: 'SETTINGS'         ,title: ''          ,width: 40  ,notSortable: true ,noExport: true
                                 ,format: ( val, oRow ) =>
                                     <div className="myShadow">
                                         <GearIcon
                                             className="alarm-settings"
                                             onClick={(event) => { set_oDialogOptions({ oRow: {...oRow} }) }}
                                         />
                                     </div>
                             }
                            ,{ name: 'ID_ALERT'         ,title: 'ID'        ,width: 20  ,format: (val) => 'A' + (val + '').padStart(2,'0') }
                            ,{ name: 'DESC_ALERT'       ,title: 'Allarme'   ,width: 340 ,isUniqueKeyForRow: true }
                ,isAdmin &&  { name: 'FLAG_ENABLED'     ,title: 'Attivo'    ,width: 40
                                ,format: ( val, oRow ) => 
                                    <div className="myShadow">
                                        <CircleIcon
                                            className={`pFlagEnabled ${ ['S','Y'].includes(val) ? 'enabled' : 'disabled' }`}
                                            onClick={ (event) => { onClickSetAlarm(event, oRow) } }
                                        />
                                    </div>
                             }
                ,isAdmin &&  { name: 'FLAG_EMAIL'       ,title: 'E-mail'     ,width: 40
                                ,format: ( val, oRow ) =>
                                    <div className="myShadow">
                                        <EmailIcon
                                            className={`pFlagEmail ${ ['S','Y'].includes(val) ? 'enabled' : 'disabled' }`}
                                            onClick={ (event) => { onClickSetAlarm(event, oRow) } }
                                        />
                                    </div>
                             }
                ,isAdmin &&  { name: 'FLAG_RECIPIENT_EMAIL' ,title: 'Lista'     ,width: 40  ,format: ( val ) => ['S','Y'].includes(val) ? '✓' : '' }
                ,isAdmin &&  { name: 'FLAG_AGENT_EMAIL'     ,title: 'Agenti'    ,width: 40  ,format: ( val ) => ['S','Y'].includes(val) ? '✓' : '' }
                            ,{ name: 'NUM_ELEM'             ,title: 'Elementi'  ,width: 60  ,format: formatNumElem ,isNum: true }
                // ,isAdmin &&  { name: 'LAST_EXECUTION'       ,title: 'Ultima esecuzione'  ,width: 150 ,format: (val) => moment(val, 'YYYYMMDDHHmmSS').format('D MMM YYYY - HH:mm') }
                ,!isDemo && isAdmin &&  { name: 'LAST_EXECUTION'       ,title: 'Ultima esecuzione'  ,width: 190 ,format: formatDate ,formatExcel: formatDateExcel }
                            ,{ name: 'ARROW'                ,title: ''          ,width: 30  ,format: formatLinkDettaglio ,notSortable: true  ,noExport: true}
                        
                        ]);
                        set_aoAllarmi(aoRecords);
                        
                    }
                } else {
                    throw new Error();
                }
                
            } catch(err) {
                console.error('ERR 100: Failed GetAllarmi: ', err);
                set_oNotifyOptions({ message: `Interrogazione fallita`, severity:'error' });
            }
            setLoading(false);
        })();

    // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[reload]);
    
    const addNum      = ( sNums, num ) => 
        ( !sNums ? [num] : [ ...sNums.split('§'), num ] )
            .map( n => +n )
            .toSorted((a, b) => a - b)
            .join('§')
    ;

    const delNum      = ( sNums, num ) => [ ...sNums.split('§') ].filter( s => s !== (num+'') ).join('§');
    
    let asParamNames  = [];
    let asParamValues = [];
    const inputParamsDB = oDialogOptions?.oRow?.INPUT_PARAMS;
    if ( inputParamsDB ) {
        asParamNames  = ( oDialogOptions?.oRow?.INPUT_PARAMS || '' ).split('§').filter( s => s !== '' ); // es. "pDaysBefore§pDaysAfter§pStatusList"
        asParamValues = ( oDialogOptions?.oRow?.INPUT_VALUES || '' ).split('§').filter( s => s !== '' ); // es. "21§28§'DRAFT,INACTIVE,PENDING_APPROVAL,PAUSED'"
    }

    function setFieldValue( sField, value ) {
        set_oDialogOptions({
             ...oDialogOptions
            ,oRow: {
                 ...oDialogOptions.oRow
                ,[sField]: value
            }
        });
    }
    
    function set_sEmailsSelectedTemp() {
        let asEmailAllfromDB    = aoUsersTemp.map(oUsers => oUsers.EMAIL_ADDRESS);
        let asEmailForce        = asEmailsSelected.filter( sEmailSelected => !asEmailAllfromDB.includes(sEmailSelected));
        let asEmailSelectedTemp = aoUsersTemp.filter( oUsers => oUsers.FLAG_SELECTED  === 'Y').map(oUsers => oUsers.EMAIL_ADDRESS);
        // console.log([ ...asEmailForce, ...asEmailSelectedTemp ].join(','));
        return [ ...asEmailForce, ...asEmailSelectedTemp ].join(',');
    }

    function set_aoUsersTempTemp() {
        let aoUsersTempTemp = [];
        
        for (let index = 0; index < aoUsers.length; index++) {
            let oUser = aoUsers[index];
            oUser.FLAG_SELECTED = (asEmailsSelected.includes(oUser.EMAIL_ADDRESS) ? 'Y' : 'N');
            aoUsersTempTemp.push(oUser);
        }
        
        set_aoUsersTemp([...aoUsersTempTemp]);
    }

    useEffect(() => {
        if (oDialogOptions) {
            set_RECIPIENT_EMAIL(( typeof oDialogOptions?.oRow?.RECIPIENT_EMAIL !== 'string' ) ? '' : oDialogOptions?.oRow?.RECIPIENT_EMAIL);
        }
    },[oDialogOptions]);

    useEffect(() => {
        if (sRECIPIENT_EMAIL) {
            set_asEmailsSelected( sRECIPIENT_EMAIL.split(',').filter(Boolean).toSorted() );
        }
    },[sRECIPIENT_EMAIL]);
    
    useEffect(() => {
        if (asEmailsSelected?.length > 0) {
            set_aoUsersTempTemp();
        }
    }, [ asEmailsSelected ]);

    return (
        <div className="component-container allarmi">
            <div className="component-card">
                <div className="component-options-wrapper">
                
                    { loading && <CircleProgress /> }
                    { /* ----- popup di modifica settings allarme ----- */ }
                    {
                        oDialogOptions &&
                            <Dialog open={ !!oDialogOptions } className="alarms set-alarm-dialog" >
                                
                                <DialogTitle>
                                    <div>{ 'Impostazioni Allarme ' + 'A' + ( oDialogOptions?.oRow?.ID_ALERT + '' ).padStart(2,'0') }</div>
                                        <div className="subtitle">
                                            <Tooltip
                                                title={ oDialogOptions?.oRow?.NOTES + '' }
                                                placement='top'
                                                arrow
                                                followCursor
                                            >
                                                <span>{ oDialogOptions?.oRow?.DESC_ALERT }</span>
                                            </Tooltip>
                                        </div>
                                    <span className='info-icon'>
                                        <CustomMaxWidthTooltip 
                                            title={
                                                <React.Fragment>
                                                  <Typography color="inherit">Impostazioni Allarme</Typography>
                                                       <br/>{ sPrefix          }
                                                  <br/><br/>{ oRegroup.lb     + ": " + oRegroup.tp     }
                                                  <br/><br/>{ oWeekDays.lb    + ": " + oWeekDays.tp    }
                                                  <br/><br/>{ oFrequency.lb   + ": " + oFrequency.tp   }
                                                       <br/>{ oFrequency.tp1   }
                                                       <br/>{ oFrequency.tp2   }
                                                       <br/>{ oFrequency.tp3   }
                                                       <br/>{ oFrequency.tp4   }
                                                  <br/><br/>{ oAgent.lb       + ": " + oAgent.tp       }
                                                  <br/><br/>{ TpRecipientRF }
                                                </React.Fragment>
                                            }
                                            placement="top" 
                                            arrow>
                                            <InfoIcon className={'infoIcon'}/>
                                        </CustomMaxWidthTooltip>
                                    </span>
                                </DialogTitle>
                                <DialogContent className="dialog-content">
                                
            <div>{
                <form autoComplete="off">
                    
                    { !inputParamsDB ? null :
                     <div className="PARAM_VALUES" data-title="Parametri di configurazione specifici dell'allarme">
                         <div>
                             {
                                 asParamNames.map((sParamName, nParamIndex) => {
                                     const { label, text, min, max } = oMappingInputs[sParamName] || {};
                                     return <CustomCssTextField
                                         key                ={ sParamName + nParamIndex }
                                         className          ={ 'param-field ' + sParamName }
                                         label              ={ label || '' }
                                         tooltiptext        ={ text  || '' }
                                         tooltipplacement   ="top"
                                         value              ={ asParamValues[nParamIndex] }
                                         onChange           ={ (event) => {
                                             let value = event.target.value;
                                             value = ((/^[0-9]*$/.test(value) && ( value >= min && value <= max )) || (value === ''))
                                                     ? (+value || 0)
                                                     : asParamValues[nParamIndex];
                                             asParamValues[nParamIndex] = value;
                                             setFieldValue('INPUT_VALUES', asParamValues.join('§'));
                                         }}
                                         onClick            ={ (event) => { event.stopPropagation() }}
                                     />
                                 })
                             }
                         </div>
                     </div>
                    }
                    
                    <div className="EMAIL_PARAMS" data-title="E-mail">
                        
                        <div className="regroupEmail">
                            
                            <input
                                id       ="regroupEmail"
                                type     ="checkbox"
                                checked  ={ oDialogOptions?.oRow?.FLAG_REGROUP_EMAIL === 'N' }
                                onChange ={ () => {
                                    setFieldValue(
                                         'FLAG_REGROUP_EMAIL' 
                                        ,['Y', 'S'].includes(oDialogOptions?.oRow?.FLAG_REGROUP_EMAIL) ? 'N' : 'S'
                                    );
                                }}
                            />
                            <label htmlFor="regroupEmail"><Tooltip title={ oRegroup.tp } placement='top' arrow followCursor>
                                <span>&nbsp;{ oRegroup.lb/* (non raggruppato con gli altri allarmi)*/}</span>
                            </Tooltip></label>
                        
                        </div>
                        
                        <hr/>
                        
                        <div className="frequenza" data-title="Frequenza">
                            
                            <div className="WEEK_DAYS_EMAIL">
                                <div className="sottotitolo">
                                    <Tooltip title={ oWeekDays.tp } placement='top' arrow followCursor>
                                        <span>{ oWeekDays.lb }</span>
                                    </Tooltip>
                                </div>
                                <div className="giorni-scelti">
                                    {Array.from({length: 5}, (_, index) => {
                                        
                                        const nWeekDay  = index + 1;
                                        const sWeekDays = ( typeof oDialogOptions?.oRow?.WEEK_DAYS_EMAIL !== 'string' )
                                                          ? '' : oDialogOptions?.oRow?.WEEK_DAYS_EMAIL;
                                        const selected  = sWeekDays.split('§').includes(nWeekDay + '');
                                        
                                        return (
                                            <React.Fragment key={'fragment' + nWeekDay}>
                                                <input
                                                    type      ="button"
                                                    value     ={ { 1: 'Lun', 2: 'Mar', 3: 'Mer', 4: 'Gio', 5: 'Ven' }[nWeekDay] }
                                                    className ={ 'dayBox ' + (selected ? 'numSelected' : '') }
                                                    onClick   ={ () => {
                                                        if (selected) {
                                                            setFieldValue('WEEK_DAYS_EMAIL', delNum(sWeekDays, nWeekDay));
                                                        } else {
                                                            setFieldValue('WEEK_DAYS_EMAIL', addNum(sWeekDays, nWeekDay));
                                                        }
                                                    }}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </div>
                            </div>
                            
                            <hr/>
                            
                            <div className="FREQUENCY_EMAIL">
                                <div className="sottotitolo">
                                    <Tooltip title={ oFrequency.tp } placement='top' arrow followCursor>
                                        <span>{ oFrequency.lb }</span>
                                    </Tooltip>
                                </div>
                                <div className="giorni-scelti">
                                    { Array.from({length: 15}, (_, index) => {
                                        
                                        const nGiorno    = index - 7;
                                        const sFrequency = ( typeof oDialogOptions?.oRow?.FREQUENCY_EMAIL !== 'string' )
                                                           ? '' : oDialogOptions?.oRow?.FREQUENCY_EMAIL;
                                        const selected   = sFrequency.split('§').includes(nGiorno + '');
                                        
                                        return (
                                            <React.Fragment key={ 'fragment' + nGiorno }>
                                                <input
                                                    type      ="button"
                                                    value     ={ ( nGiorno > 0 ) ? ( '+' + nGiorno ) : nGiorno }
                                                    className ={ 'dayBox ' + ( selected ? 'numSelected' : '' ) }
                                                    onClick   ={ () => {
                                                        if ( selected ) {
                                                            setFieldValue( 'FREQUENCY_EMAIL', delNum( sFrequency, nGiorno ) );
                                                        } else {
                                                            setFieldValue( 'FREQUENCY_EMAIL', addNum( sFrequency, nGiorno ) );
                                                        }
                                                    }}
                                                />
                                            </React.Fragment>
                                        );
                                        
                                    })}
                                </div>
                            </div>
                        
                        </div>
                        
                        <hr/>
                        
                        <div className="destinatari" data-title="Destinatari">
                            
                            <div className="FLAG_AGENT_EMAIL">
                                <input
                                    id       ="agentEmail"
                                    type     ="checkbox"
                                    checked  ={ oDialogOptions?.oRow?.FLAG_AGENT_EMAIL === 'S' }
                                    onChange ={ () => {
                                        setFieldValue(
                                             'FLAG_AGENT_EMAIL'
                                            ,['Y', 'S'].includes(oDialogOptions?.oRow?.FLAG_AGENT_EMAIL) ? 'N' : 'S'
                                        );
                                    }}
                                />
                                <label htmlFor="agentEmail"><Tooltip title={ oAgent.tp } placement='top' arrow followCursor>
                                <span>&nbsp;{ oAgent.lb }</span></Tooltip></label>
                            </div>
                            
                            <div className="FLAG_RECIPIENT_EMAIL">
                                <input
                                    id       ="recipientEmail"
                                    type     ="checkbox"
                                    checked  ={ oDialogOptions?.oRow?.FLAG_RECIPIENT_EMAIL === 'S' }
                                    onChange ={ () => {
                                        setFieldValue(
                                             'FLAG_RECIPIENT_EMAIL'
                                            ,['Y', 'S'].includes(oDialogOptions?.oRow?.FLAG_RECIPIENT_EMAIL) ? 'N' : 'S'
                                        );
                                    }}
                                />
                                <label htmlFor="recipientEmail">
                                    <Tooltip title={ TpRecipientRF } placement='top' arrow followCursor>
                                        <span>&nbsp;{ oRecipientFl.lb }</span>
                                    </Tooltip>
                                </label>
                            </div>

                            { ['Y', 'S'].includes(oDialogOptions?.oRow?.FLAG_RECIPIENT_EMAIL) &&
                                <Autocomplete
                                    id        ="autocompleteEmails"
                                    className ="RECIPIENT_EMAIL"
                                    freeSolo
                                    multiple
                                    disableCloseOnSelect
                                    limitTags       ={ 4 }
                                    clearOnBlur     ={ true }
                                    renderInput     ={ (params) => (
                                        <CustomCssTextField
                                            {...params}
                                            className="AutocompletePopup"
                                        />
                                    )}
                                    renderTags  ={ ( value, getTagProps ) =>
                                        value.map( ( option, index ) => {
                                            if ( option === '+' ) {
                                                // in questo modo il (+) non avrà la "x" per eliminarlo
                                                return <Chip
                                                    className = "addEmailButton"
                                                    key       = "+"
                                                    onClick   = { () => {
                                                        set_oDialogRecipientOptions({
                                                            ID_ALERT: oDialogOptions.oRow.ID_ALERT
                                                        })
                                                    } }
                                                    label     = {option}
                                                />;
                                            } else {
                                                return <Chip label={option} key={value} {...getTagProps({index})} />;
                                            }
                                        })
                                    }
                                    value       ={ ['+', ...asEmailsSelected] }
                                    options     ={ [] }
                                    onChange    ={ ( event, asSelectedValues ) => {
                                        // nell'onChange NON uso uno stato apposta per questo Autocomplete,
                                        // ma modifico direttamente il parametro RECIPIENT_EMAIL che passerò poi al DB
                                        setFieldValue('RECIPIENT_EMAIL', asSelectedValues.filter(s => s !== '+').join(','));
                                    }}
                                /> }
                            
                        </div>
                        
                    </div>
                
                </form>
            }</div>
                                
                                </DialogContent>
                                
                                <DialogActions className="dialogButton">
                                    <>
                                        { loading ? null :
                                            <Button
                                                onClick   ={() => {onClickSetAlarm(null, oDialogOptions.oRow)}}
                                                className ="popupConfirm"
                                            >salva</Button>
                                        }
                                        <Button
                                            onClick   ={() => {set_oDialogOptions(null)}}
                                            className ="popupAnnul"
                                        >annulla</Button>
                                    </>
                                </DialogActions>
                            
                            </Dialog>
                    }
                    {
                        oDialogRecipientOptions &&
                        <Dialog open={ !!oDialogRecipientOptions } className="alarms set-alarm-dialog dialog-users" >
                            <DialogTitle>
                                <div>
                                    <Tooltip title="Selezionare gli utenti che devono ricevere l'allarme" placement='top' arrow followCursor>
                                        <span>
                                            { 'Utenti DIS24 per Lista di distribuzione Allarme ' + 'A' 
                                                + ( oDialogRecipientOptions.ID_ALERT + '' ).padStart(2,'0') }
                                        </span>
                                    </Tooltip>
                                </div>
                            </DialogTitle>
                            <DialogContent className="dialog-content">
                                <div className="component-options-wrapper">
                                {
                                    loading ? <></> : <>{
                                        aoUsers?.length && aoUsersHeaders?.length ? 
                                            <SimpleTable
                                                chiave            ={ 'EMAIL-LIST' }
                                                sTableDataType    ={ 'EMAIL-LIST' }
                                                aoRows            ={ aoUsers }
                                                aoCols            ={ aoUsersHeaders }
                                                isEditable        ={ false }
                                                oRowOptions       ={{ addClass: (oRow) => ( oRow['FLAG_ATTIVO'] || '' ) === 'N' ? ' user-disabled ' : '' }}
                                                oSortOptions      ={ { isSortable: true } }
                                                oCheckOptions     ={ { isEnabled: true, isVisible: true } }
                                                isCheckedFunction ={ isCheckedFunction }
                                                fCheckAll         ={ bCheckAll => fCheckAll( { bCheckAll, aoRows: aoUsersTemp, setAoRows: set_aoUsersTemp } ) }
                                                fCheckOne         ={ ({ isChecked, oRow }) => fCheckOne( { isChecked, oRow, aoRows: aoUsersTemp, setAoRows: set_aoUsersTemp } ) }
                                                oExportOption     ={ { isExportable: false }}
                                            />
                                        : <div className="component-options-wrapper-alarmNoRecord">Loading...</div>
                                    }</>
                                }
                                </div>
                            </DialogContent>
                                <DialogActions className="dialogButton">
                                <>
                                    { loading ? null :
                                        <Button
                                            onClick={() => {
                                                setFieldValue('RECIPIENT_EMAIL', set_sEmailsSelectedTemp() );
                                                set_oDialogRecipientOptions(null);
                                            }}
                                            className="popupConfirm"
                                        >conferma</Button>
                                    }
                                    <Button
                                        onClick={() => {
                                            set_oDialogRecipientOptions(null);
                                            set_aoUsersTempTemp();
                                        }}
                                        className="popupAnnul"
                                    >annulla</Button>
                                </>
                            </DialogActions>
                        
                        </Dialog>
                    }
                    { loading ? null :
                        ( aoAllarmi?.length && aoAllarmiHeaders?.length )
                            ? <SimpleTable
                                chiave         ="ALERTS"
                                sTableDataType ="ALERTS"
                                aoRows         ={ aoAllarmi }
                                aoCols         ={ aoAllarmiHeaders }
                                oSortOptions   ={ { isSortable: true } }
                                oExportOption  ={ { sFileName: 'Allarmi' } }
                                noFilter       ={ true }
                                randomizeOnlyNumbersForDemo = { true }
                            />
                            : <div className="component-options-wrapper-alarmNoRecord">
                                <FolderOffIcon/>&nbsp; Nessun risultato trovato
                            </div>
                    }
                
                </div>
            </div>
        </div>
    )
};

export default Allarmi;
